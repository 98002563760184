import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useBlockLayout, useExpanded, useFilters, useGroupBy, useSortBy, useTable} from 'react-table';
import {FixedSizeList} from 'react-window';
import {darkTeal, lightGreen, veryLightGreen} from "./BaseComponents";
import ReactTooltip from "react-tooltip";
import _ from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import * as far from "@fortawesome/pro-regular-svg-icons";
import {useWindowSize} from "../hooks/useWindowSize";
import Measure from "react-measure";
import {useTranslation} from "react-i18next";

const Styles = styled.div`
  max-width: 100%;

  .table {
    display: inline-block;
    border-spacing: 0;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .header-row {
      background: ${darkTeal};
    }

    //:nth-child(odd) .td {
    .odd .td {
      background: ${veryLightGreen};
    }

    //:nth-child(even) .td {
    .even .td {
      background: ${lightGreen};
    }

    .th {
      background: ${darkTeal};
      color: white;
      font-weight: bold;
      border-bottom: 1px solid white;

      //:not(:last-child) {
      border-right: 1px solid white;
      //}
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
    }

    .aggregateCell {
      background: lightgray;
    }
  }
`;

export const InnerCellWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DefaultCellComponent = ({cell, value, dataTip}) => {
    const [contentWidth, setContentWidth] = useState(0);
    const [contentScrollWidth, setContentScrollWidth] = useState(0);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const currentValue = value ?? cell.value;

    useLayoutEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    useEffect(() => {
        setIsOverflowing(contentWidth < contentScrollWidth);
    }, [contentWidth, contentScrollWidth]);

    return (
        <Measure bounds onResize={contentRect => {
            setContentWidth(contentRect.bounds.width);
        }}>{({measureRef}) => (
            <InnerCellWrapper>
                <Measure scroll onResize={contentRect => {
                    setContentScrollWidth(contentRect.scroll.width);
                }}>{({measureRef}) => (
                    <div
                        ref={measureRef}
                        data-tip={(dataTip ? `${dataTip} ` : '') + (isOverflowing ? currentValue : '')}
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {currentValue}
                    </div>
                )}</Measure>
            </InnerCellWrapper>
        )}</Measure>
    );
};

const Table = ({columns, data, rowSize = 35, maxHeight, ...rest}) => {
    const [windowWidth, windowHeight] = useWindowSize();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    // Use the state and functions returned from useTable to build your UI
    const defaultColumn = useMemo(() => ({
        width: 120,
        Cell: DefaultCellComponent,
        disableGroupBy: true,
        filter: "text",
        Filter: TextColumnFilter,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            autoResetPage: false,
            autoResetExpanded: false,
            autoResetGroupBy: false,
            autoResetSelectedRows: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            autoResetResize: false,
            ...rest, //Non-default props are available in all cells
        },
        useBlockLayout,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
    );

    const scrollingRef = useRef();

    useLayoutEffect(() => {
        ReactTooltip.rebuild();
    }, [scrollingRef.current]);

    const RenderRow = useCallback(({index, style, isScrolling}) => {
        scrollingRef.current = isScrolling;
        const row = rows[index];
        prepareRow(row);
        return (
            <div {..._.merge(row.getRowProps({style}), {style: {width: totalColumnsWidth}})}
                 className={`tr ${index % 2 === 1 ? "odd" : "even"}`}>
                {row.cells.map(cell => {
                    return (
                        <div
                            {..._.merge(cell.getCellProps(), {style: cell.column.getTdStyle?.(cell)})}
                            className={`td ${cell.isGrouped ? "groupCell"
                                : cell.isAggregated ? "aggregateCell"
                                    : cell.isPlaceholder ? "placeholderCell" : ""}`}
                        >
                            {cell.isGrouped ? (
                                // If it's a grouped cell, add an expander and row count
                                <div style={{display: "flex", flex: "1 1 100%", minWidth: 0}}>
                                    <span {..._.merge(row.getToggleRowExpandedProps(), {style: {padding: isRtl ? '0 0 0 4px' : '0 4px 0 0'}})}> 
                                        {row.isExpanded ?
                                            <FontAwesomeIcon icon={fas.faCaretDown}/> :
                                            <FontAwesomeIcon icon={fas.faCaretRight}/>
                                        }
                                    </span>
                                    {cell.render('Cell')}
                                    <div style={{
                                        padding: isRtl ? '0 4px 0 0' : '0 0 0 4px',
                                        margin: "auto",
                                        fontWeight: "bold",
                                    }}>
                                        ({(row.leafRows ?? row.subRows).length})
                                    </div>
                                </div>
                            ) : cell.isAggregated ? (
                                // If the cell is aggregated, use the Aggregated
                                // renderer for cell
                                cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                // Otherwise, just render the regular cell
                                cell.render('Cell')
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }, [prepareRow, rows]);

    const [tableHeaderHeight, setTableHeaderHeight] = useState();
    const [tableWrapperWidth, setTableWrapperWidth] = useState();
    const listRef = useRef();
    const [listHorizontalScroll, setListHorizontalScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setListHorizontalScroll(listRef.current?.scrollLeft);
        };

        listRef.current?.addEventListener("scroll", handleScroll);

        return () => {
            listRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const horizontalScrollbarSpace = listRef.current ? listRef.current.clientWidth < listRef.current.scrollWidth ? 17 : 0 : 0;
    const verticalScrollbarSpace = listRef.current ? listRef.current.clientHeight < listRef.current.scrollHeight ? 17 : 0 : 0;

// Render the UI for your table
    return (
        <div>
            <Measure bounds scroll onResize={contentRect => {
                setTableWrapperWidth(contentRect.bounds.width);
            }}>{({measureRef}) => (
                <div ref={measureRef}
                     style={{
                         overflowX: 'hidden',
                         width: totalColumnsWidth + verticalScrollbarSpace,
                         maxWidth: 'calc(100vw - 37px)',
                     }}>
                    <Styles>
                        <div {...getTableProps()} className="table">
                            <Measure bounds onResize={contentRect => {
                                setTableHeaderHeight(contentRect.bounds.height);
                            }}>{({measureRef}) => (
                                <div ref={measureRef} style={{position: 'relative', left: -listHorizontalScroll}}
                                     className="header-row">
                                    {headerGroups.map(headerGroup => (
                                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                                            {headerGroup.headers.map(column => (
                                                <div {...column.getHeaderProps()} className="th">
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'start',
                                                    }}>
                                                        {column.canGroupBy ? (
                                                            <div>
                                                            <span
                                                                {...column.getGroupByToggleProps()}
                                                                style={{
                                                                    padding: isRtl ? '0 0 0 5px' : '0 5px 0 0',
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {column.isGrouped ?
                                                                    <FontAwesomeIcon
                                                                        icon={fas.faDotCircle}/> :
                                                                    <FontAwesomeIcon
                                                                        icon={far.faDotCircle}/>
                                                                }
                                                            </span>
                                                            </div>
                                                        ) : null}
                                                        {column.render('Header')}
                                                        <span
                                                            {...column.getSortByToggleProps()}
                                                            style={{
                                                                padding: isRtl ? '0 5px 0 0' : '0 0 0 5px',
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                        {column.canSort ? column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <FontAwesomeIcon
                                                                    icon={fas.faSortDown}/>
                                                                : <FontAwesomeIcon
                                                                    icon={fas.faSortUp}/>
                                                            : <FontAwesomeIcon
                                                                icon={fas.faSort}/> : null}
                                                    </span>
                                                    </div>
                                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            )}</Measure>
                            <div {...getTableBodyProps()}>
                                <FixedSizeList
                                    outerRef={listRef}
                                    height={Math.max(Math.min((maxHeight ? maxHeight - 2 : windowHeight) - (tableHeaderHeight ?? 0) - 25 - horizontalScrollbarSpace, rows.length * rowSize + 1), rowSize * Math.min(rows.length, 4) + horizontalScrollbarSpace)}
                                    itemCount={rows.length}
                                    itemSize={rowSize}
                                    width={tableWrapperWidth}
                                    direction={i18n.dir()}
                                >
                                    {RenderRow}
                                </FixedSizeList>
                            </div>
                        </div>
                    </Styles>
                </div>
            )}</Measure>
        </div>
    );
};

export function TextColumnFilter(
    {
        column: {
            filterValue, preFilteredRows, setFilter,
        },
    },
) {
    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search...`}
            style={{margin: "auto", width: "calc(100% - 10px)"}}
        />
    );
}

export const filterTypes = {
    text: (rows, id, filterValue) => {
        const target = String(filterValue).toLowerCase();
        return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .includes(target)
                : true;
        });
    },
};

export function SelectColumnFilter(
    {
        column: {
            filterValue, setFilter, preFilteredRows, id,
            selectGetOptionLabel = b => b,
        },
    },
) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            style={{width: "100%"}}
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {selectGetOptionLabel(option) || option}
                </option>
            ))}
        </select>
    );
}

export default Table;
