import React from 'react';
import styled from "styled-components";
import {mediumGreen, veryDarkTeal} from "../Components/BaseComponents";

const FooterWrapper = styled.div`
  color: white;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background: ${veryDarkTeal};
  padding: 0 20px;
`;

const EmailLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: ${mediumGreen};
    text-decoration: underline;
  }
`;

export const Footer = () => {

    return (
        <FooterWrapper>
            <div style={{width: '250px', textAlign: 'left'}}>© 2021 · NCG PUBLICATIONS LTD</div>
            <div style={{textAlign: 'center'}}>
                <EmailLink href="mailto:ads@newcomersguide.co.il">ads@newcomersguide.co.il</EmailLink>
                <div>1-599-500-605</div>
                <div>POB 41064 Jerusalem, 91410</div>
            </div>
            <div style={{width: '250px', textAlign: 'right', fontSize: '10px', fontWeight: 'normal'}}>
                Created by{' '}
                <EmailLink href="mailto:info@guggrp.com">Guggenheim Group LTD</EmailLink>
            </div>
        </FooterWrapper>
    );
};