import {useEffect} from "react";
import _ from "lodash";
import {toast} from "react-toastify";

export const useToastMessages = messageArray => {
    useEffect(() => {
        toastMessages(messageArray);
    }, [messageArray]);
};

export const toastMessages = (messageArray, onSuccess) => {
    _.map(messageArray, m => {
        const {message, type, reason} = m;
        if (message) {
            if (type === 'success') {
                toast.success(message);
                onSuccess?.();
                if (reason) console.log(reason);
            } else if (type === 'error') {
                toast.error(message);
                if (reason) console.error(reason);
            }
        }
    });
};
