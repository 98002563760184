import React, {useCallback} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import logo from "../images/newcomers-guide-logo.png";
import {StyledSelect} from "../Components/Input";
import {darkGreen, darkTeal, veryDarkGreen} from "../Components/BaseComponents";
import {useAuth} from "../hooks/useAuth";
import {NavLink} from "react-router-dom";
import {Auth} from "aws-amplify";

const languageOptions = [
    {label: 'English', value: 'en'},
    {label: 'עברית', value: 'he'},
];

const StyledLanguageSelect = styled(StyledSelect)`
  & .react-select__control--is-focused {
    box-shadow: none;
  }
`;

const headerHeight = 55;

const HeaderWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  height: ${headerHeight}px;
  box-shadow: 0 3px 6px #00000029;
  z-index: 999;
`;

const HeaderSideWrapper = styled.div`
  width: 400px;
  min-width: 400px;
`;

const HeaderCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledNavLink = styled(NavLink)`
  margin: 0 8px;
  text-decoration: none;
  font-weight: bold;
  color: ${darkGreen};

  &:hover {
    color: ${veryDarkGreen};
    text-decoration: underline;
  }

  &.active {
    color: ${veryDarkGreen};
    text-decoration: underline;
  }
`;

const StyledSignOut = styled.div`
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  color: ${darkGreen};

  &:hover {
    color: ${veryDarkGreen};
    text-decoration: underline;
  }
`;

export const Header = () => {
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";
    const {email, loggedIn} = useAuth();
    const changeLanguage = useCallback(async (lng) => {
        await i18n.changeLanguage(lng.value);
    }, [i18n]);

    return (
        <HeaderWrapper>
            <HeaderSideWrapper>
                <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <a href="https://newcomersguide.co.il/">
                        <img
                            src={logo}
                            style={{padding: '10px 10px 0 10px', width: '240px', objectFit: 'contain'}}
                            alt={t('header.logo')}
                        />
                    </a>
                    <StyledLanguageSelect
                        value={languageOptions.find(x => x.value === i18n.language)}
                        options={languageOptions}
                        onChange={changeLanguage}
                        styles={{
                            control: base => ({
                                border: 'none',
                            }),
                            indicatorSeparator: base => ({
                                ...base,
                                background: 'none',
                            }),
                        }}
                    />
                </div>
            </HeaderSideWrapper>
            <HeaderCenterWrapper style={{color: darkTeal, fontWeight: 'bold', textAlign: 'center', width: '100%'}}>
                <div style={{borderBottom: `1px dashed ${darkTeal}`, fontSize: '20px'}}>
                    {t('header.title')}
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <StyledNavLink to={'/'} exact={true}>Home</StyledNavLink>
                    {loggedIn &&
                    <StyledNavLink to={'/admin'}>Admin</StyledNavLink>
                    }
                    <StyledNavLink to={{pathname: 'https://newcomersguide.co.il/'}} target={'_blank'}>
                        About Us
                    </StyledNavLink>
                </div>
            </HeaderCenterWrapper>
            <HeaderSideWrapper>
                <div style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: '0 10px',
                }}>
                    {loggedIn ?
                        <div style={{textAlign: 'right'}}>
                            {email}
                            <StyledSignOut onClick={async () => await Auth.signOut()}>Sign Out</StyledSignOut>
                        </div> : <StyledNavLink to={'/login'}>Sign In</StyledNavLink>
                    }
                </div>
            </HeaderSideWrapper>
        </HeaderWrapper>
    );
};