import styled from 'styled-components';
import {darkPurple, lightPurple, mediumPurple, veryLightPurple} from "./BaseComponents";

const ButtonDiv = styled.button`
  font-weight: bold;
  font-size: 16px;
  color: ${darkPurple};
  text-align: center;
  padding: 8px 12px;
  border: 1px solid ${darkPurple};
  border-radius: 5px;
  background: ${veryLightPurple};

  ${({disabled, active}) => !disabled ? `
    cursor: pointer;
    :hover {
      background: ${lightPurple};
    }
    ${active ? `
      background: ${mediumPurple};
      color: white;
      ` : ''}
  ` : `
    opacity: 0.5;
  `}
`;

export const Button = ({children, onClick, disabled, active, ...rest}) => (
    <ButtonDiv disabled={disabled} onClick={!disabled ? onClick : undefined} active={active} {...rest}>
      {children}
    </ButtonDiv>
);