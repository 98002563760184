import styled from "styled-components";

export const veryLightGreen = '#edf5de';
export const lightGreen = '#deedc2';
export const mediumGreen = '#9ccb3d';
export const darkGreen = '#628d0c';
export const veryDarkGreen = '#2d4300';

export const veryLightPurple = '#dedcf5';
export const lightPurple = '#8883c8';
export const mediumPurple = '#433c97';
export const darkPurple = '#1a1467';
export const veryDarkPurple = '#060330';

export const veryLightOrange = '#fff0de';
export const lightOrange = '#ffd098';
export const mediumOrange = '#d99441';
export const darkOrange = '#94550a';
export const veryDarkOrange = '#452600';

export const veryLightTeal = '#a5e8e8';
export const lightTeal = '#46b4b4';
export const mediumTeal = '#009a9a';
export const darkTeal = '#03515d';
export const veryDarkTeal = '#024752';

export const veryLightPink = '#fcdaf1';
export const lightPink = '#f3a7da';
export const mediumPink = '#d753ac';
export const darkPink = '#c90089';
export const veryDarkPink = '#7d0054';

export const mediumBrickRed = '#c95f3b';

export const brightRed = '#ff0000';
export const brightOrange = '#ffa500';

export const PageWrapper = styled.div`
  padding: 10px;
`;

export const PageTitle = styled.div`
  color: ${veryDarkTeal};
  text-align: center;
  font-size: 36px;
  font-weight: bold;
`;

export const SecondaryTitle = styled.div`
  color: ${veryDarkTeal};
  font-weight: bold;
  font-size: 18px;
  margin: 0 10px;
`;

export const Heading = styled.div`
  color: ${veryDarkTeal};
  text-align: center;
  font-weight: bold;
  font-size: 24px;
`;

export const FieldsWrapperGrid = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 10px;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${darkTeal}44;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  background: white;
`;

export const FieldTitle = styled.div`
  color: ${darkGreen};
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
`;

export const FieldContent = styled.div`
  color: ${darkTeal};
  padding: 0 5px;
`;

export const IconButtonDiv = styled.div.attrs(props => ({
    onClick: props.disabled ? undefined : props.onClick,
}))`
  color: ${({color = '#888888'}) => color};
  ${({disabled, hoverColor = '#444444'}) => disabled ? `
    opacity: 0.15;
  ` : `
    cursor: pointer;
    &:hover {
      color: ${hoverColor};
    }
  `}
`;