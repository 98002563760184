import React, {createContext, Suspense} from 'react';
import Amplify from 'aws-amplify';
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {ReactQueryDevtools} from 'react-query/devtools';
import styled from 'styled-components';
import {Redirect} from "react-router";
import ReactTooltip from 'react-tooltip';
import {Contract} from "./Pages/Contract";
import {Header} from "./Pages/Header";
import './i18n/i18n';
import {useTranslation} from 'react-i18next';
import {useOverlay} from "./hooks/useOverlay";
import {Admin} from "./Pages/Admin";
import {LandingPage} from "./Pages/LandingPage";
import "filepond/dist/filepond.min.css";
import {Footer} from "./Pages/Footer";
import {useModal} from "./hooks/useModal";
import {PaymentStatus} from "./Pages/PaymentStatus";
import {Login} from "./Pages/Login";
import {useAuth} from "./hooks/useAuth";
import {mediumGreen} from "./Components/BaseComponents";
import * as far from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


Amplify.configure({
    aws_cognito_region: "eu-west-2",
    aws_user_pools_id: "eu-west-2_uaLN3IxlV",
    aws_user_pools_web_client_id: "3ffdkc3758pbg9bd1puo3pbehh",
});

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // refetchOnWindowFocus: false,
            // refetchOnMount: false,
            // refetchOnReconnect: false,
            // retry: false,
            // staleTime: twentyFourHoursInMs,
        },
    },
});

export const AppFunctionsContext = createContext();

const ReactTooltipContainer = styled.div`
  .react-tooltip {
    z-index: 20000;
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 30vw;
  overflow-wrap: break-word;
  white-space: normal;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const BackgroundComponent = () => {
    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    color: `${mediumGreen}20`,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '500px',
                    zIndex: '-1',
                }}
            >
                <FontAwesomeIcon icon={far.faBookOpen}/>
            </div>
            <div
                style={{
                    position: 'fixed',
                    background: `${mediumGreen}10`,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: '-2',
                }}
            />
        </>
    );
};

function App() {
    const {modalComponent, openModal, closeModal} = useModal();
    const {overlayComponent, addToOverlaySet, removeFromOverlaySet} = useOverlay();

    return (
        <QueryClientProvider client={queryClient}>
            <AppFunctionsContext.Provider
                value={{addToOverlaySet, removeFromOverlaySet, openModal, closeModal}}
            >
                <Routes/>
                {modalComponent}
                {overlayComponent}
                <ReactTooltipContainer>
                    <StyledReactTooltip
                        multiline={true}
                        delayShow={200}
                        className={'react-tooltip'}
                    />
                </ReactTooltipContainer>
                <ToastContainer
                    hideProgressBar={true}
                    position={"bottom-center"}
                    style={{zIndex: 20000}}
                    toastStyle={{zIndex: 20000}}
                    bodyStyle={{zIndex: 20000}}
                />
                <ReactQueryDevtools/>
            </AppFunctionsContext.Provider>
        </QueryClientProvider>
    );
}

export default App;


const Routes = () => {
    const {t, i18n} = useTranslation();

    return (
        <Router>
            <Switch>
                <Route exact path="/payment/:id">
                    <PaymentStatus/>
                </Route>
                <ContentWrapper style={{direction: i18n.dir()}}>
                    <Header/>
                    <div style={{height: '100%', overflow: 'auto'}}>
                        <BackgroundComponent/>
                        <Suspense fallback={<div>Loading</div>}>
                            <Switch>
                                <Route exact path="/contracts/:id">
                                    <Contract/>
                                </Route>
                                <Route path="/login">
                                    <Login/>
                                </Route>
                                {<AdminRoute path="/admin">
                                    <Admin/>
                                </AdminRoute>}
                                <Route path="/" exact={true}>
                                    <LandingPage/>
                                </Route>
                                <Redirect to="/"/>
                            </Switch>
                        </Suspense>
                    </div>
                    <Footer/>
                </ContentWrapper>
            </Switch>
        </Router>
    );
};

function AdminRoute({children, ...rest}) {
    const {loggedIn, isAdmin} = useAuth();
    return (
        <Route {...rest} render={({location}) => {
            return loggedIn === true
                ? !isAdmin ? "Forbidden" : children
                : <Redirect to={{
                    pathname: '/login',
                    state: {from: location},
                }}/>;
        }}/>
    );
}