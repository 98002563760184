import React, {useCallback, useContext, useEffect, useState} from "react";
import _ from 'lodash';
import {AdDetailsTable, AdTableRow, StyledAdDetailsTable} from "./AdDetailsTable";
import {ImageDisplay} from "../Components/ImageDisplay";
import {useTranslation} from "react-i18next";
import {useMutation, useQueryClient} from "react-query";
import {rejectAd, updateAdApprovalState} from "../functions/api";
import {toastMessages} from "../hooks/useToastMessages";
import {AppFunctionsContext} from "../App";
import {toast} from "react-toastify";
import {StyledSelect} from "../Components/Input";
import {Button} from "../Components/Button";
import {darkGreen} from "../Components/BaseComponents";

const postApprovalOverlay = 'postApprovalOverlay';
const approvalOptions = [{value: "draft", label: "Draft"}, {value: "approved", label: "Approved"}, {
    value: "rejected",
    label: "Rejected",
}];

export const ContractDetailsModal = ({contract, contractDetails}) => {
    const {addToOverlaySet, removeFromOverlaySet} = useContext(AppFunctionsContext);
    const [imageLineItem, setImageLineItem] = useState();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";
    const [originalApprovalState, setOriginalApprovalState] = useState();
    const [approvalState, setApprovalState] = useState();
    const [message, setMessage] = useState('Hi,\n' +
        '\n' +
        'There was an issue with the image that you uploaded on the portal.\n' +
        '\n' +
        'Please submit an Ad image that conforms to the requirements as soon as possible.\n' +
        '\n' +
        'Thanks,\n' +
        'The NCG Team');
    const queryClient = useQueryClient();

    const {
        guid: contractGuid,
    } = contract ?? {};

    const {
        adImageId,
        approved,
    } = imageLineItem ?? {};

    useEffect(() => {
        setApprovalState(approved ? "approved" : "draft");
        setOriginalApprovalState(approved ? "approved" : "draft");
    }, [approved]);

    const updateApprovalQuery = useMutation({
        mutationFn: (props) => {
            return updateAdApprovalState(props);
        }, mutationKey: "updateAdApprovalState",
    });

    const rejectAdQuery = useMutation({
        mutationFn: (props) =>
            rejectAd(props), mutationKey: "rejectAd",
    });

    const submitApprovalState = useCallback(async () => {
        try {
            addToOverlaySet(postApprovalOverlay);
            let ret;
            switch (approvalState) {
                case "rejected":
                    if (!message) {
                        toast.error("Rejection Message is Required");
                        return;
                    }
                    ret = await rejectAdQuery.mutateAsync({adImageId, message});
                    if (ret.success) {
                        setImageLineItem(undefined)
                    }
                    break;
                case "approved":
                case "draft":
                    ret = await updateApprovalQuery.mutateAsync({
                        adImageId,
                        approvalStatus: approvalState === "approved"
                    });
                    if (ret.success) {
                        setImageLineItem({
                            ...imageLineItem,
                            approved: approvalState === "approved" ? new Date() : undefined
                        })
                    }
                    break;
            }

            if (!ret) {
                return;
            }

            const {
                success,
                messageArray,
            } = ret || {};
            if (success) {
                queryClient.invalidateQueries(["getAdminMasterLists"]);
            }

            toastMessages(messageArray);
        } finally {
            removeFromOverlaySet(postApprovalOverlay);
        }
    }, [message, adImageId, approvalState, imageLineItem]);

    const handleChangeState = useCallback(async ({value}) => {
        setApprovalState(value);
    }, []);

    return (
        <div style={{
            height: imageLineItem ? '90vh' : undefined,
            width: '90vw',
            display: 'flex',
            flexDirection: 'column',
        }}>
            {!imageLineItem ?
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <AdDetailsTable
                        contract={contract}
                        contractDetails={contractDetails}
                        admin={true}
                        imageLineItem={imageLineItem}
                        setImageLineItem={setImageLineItem}
                    />
                </div>
                :
                <>
                    <StyledAdDetailsTable isRtl={isRtl}>
                        <tbody>
                        <AdTableRow
                            className={'show-one-row'}
                            row={imageLineItem}
                            contract={contract}
                            contractDetails={contractDetails}
                            admin={true}
                            imageLineItem={imageLineItem}
                            setImageLineItem={setImageLineItem}
                        />
                        </tbody>
                    </StyledAdDetailsTable>
                    <div style={{borderBottom: `1px solid ${darkGreen}`}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            padding: '5px',
                        }}>
                            <div style={{width: '130px'}}>
                                <StyledSelect
                                    value={_.find(approvalOptions, a => a.value === approvalState) || approvalOptions[0]}
                                    options={approvalOptions}
                                    onChange={handleChangeState}
                                    styles={{
                                        indicatorSeparator: base => ({
                                            ...base,
                                            background: 'none',
                                        }),
                                    }}
                                />
                            </div>
                            <Button
                                disabled={approvalState === originalApprovalState || approvalState === 'rejected' && !message}
                                onClick={submitApprovalState}
                                style={{marginLeft: '5px', width: '130px'}}
                            >Submit</Button>
                        </div>
                        {approvalState === "rejected" &&
                        <div style={{textAlign: 'center', color: 'red'}}>
                            <div style={{fontWeight: 'bold'}}>
                                Rejection Reason (this will be emailed to the company)
                            </div>
                            <textarea
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                style={{width: '400px', height: '150px'}}
                            />
                        </div>
                        }
                    </div>
                    <div style={{flexGrow: '1', overflow: 'auto'}}>
                        <ImageDisplay contractGuid={contractGuid} contractLineItem={imageLineItem}/>
                    </div>
                </>
            }
        </div>
    );
};