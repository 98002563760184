import React, {useCallback} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from '@fortawesome/pro-solid-svg-icons';
import {useStateRef} from "./useStateRef";
import {darkGreen, veryLightGreen} from "../Components/BaseComponents";

const OverlayDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: ${veryLightGreen}44;
  color: ${darkGreen}88;
  font-size: 70px;
  font-weight: bold;
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const useOverlay = () => {
    const [overlaySet, setOverlaySet, overlaySetRef] = useStateRef(new Set());

    const addToOverlaySet = useCallback(value => {
        const newSet = new Set(overlaySetRef.current);
        newSet.add(value);
        setOverlaySet(newSet);
    }, []);

    const removeFromOverlaySet = useCallback(value => {
        const newSet = new Set(overlaySetRef.current);
        newSet.delete(value);
        setOverlaySet(newSet);
    }, []);

    const overlayComponent = <>
        {overlaySet.size > 0 &&
        <OverlayDiv>
            <FontAwesomeIcon icon={fas.faCog} spin/>
        </OverlayDiv>
        }
    </>;

    return {overlayComponent, addToOverlaySet, removeFromOverlaySet};
};