import React, {useCallback, useContext, useMemo, useRef, useState} from "react";
import {AppFunctionsContext} from "../App";
import {useMutation, useQueryClient} from "react-query";
import {deleteAd, uploadAd} from "../functions/api";
import _ from "lodash";
import {toast} from "react-toastify";
import {darkGreen, darkPurple, lightGreen} from "../Components/BaseComponents";
import {Button} from "../Components/Button";
import {Formik} from "formik";
import {FilePondField} from "../Components/Input";
import {fitsResolutionRequirement, getMmFromPxDpi, getValidResolutions} from "../functions/shared";
import {toastMessages} from "../hooks/useToastMessages";
import {ImageDisplay} from "../Components/ImageDisplay";
import {PDFDocument, PDFRawStream} from 'pdf-lib'
import ExifReader from 'exifreader'
import styled from "styled-components";

const SpecificationTable = styled.table`
  border-collapse: collapse;
  margin: auto;
  width: fit-content;

  td {
    padding: 6px;
  }

  thead, .emphasize {
    font-weight: bold;
    color: ${darkGreen};
    width: 140px;
  }

  th {
    border-bottom: 1px solid ${lightGreen};
    border-top: 1px solid ${lightGreen};
    text-align: left;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid ${lightGreen};
  }
`;

export const UploadModal = ({contractGuid, contractLineItem}) => {
        const {closeModal} = useContext(AppFunctionsContext);
        const queryClient = useQueryClient();
        const [imageProperties, setImageProperties] = useState()

        const {
            id,
            adImageId,
            adFilename,
            approved,
            type,
        } = contractLineItem;

    const resolutions = getValidResolutions(type);
    const matchedResolution = useMemo(() =>
            imageProperties && _.find(resolutions, r => fitsResolutionRequirement([imageProperties.width, imageProperties.height], r))
        , [imageProperties, resolutions]);
    console.log({matchedResolution, imageProperties, resolutions})
    const uploadRef = useRef();

    const uploadQ = useMutation("uploadAd", (data) => uploadAd({contractDetailId: id, data}));
    const deleteQ = useMutation("deleteAd", deleteAd);
    const handleDelete = useCallback(async () => {
        const ret = await deleteQ.mutateAsync({contractGuid, adImageId});

        const {
            messageArray,
        } = ret || {};

            toastMessages(messageArray, () => {
                queryClient.invalidateQueries(["getContract", contractGuid]);
                closeModal();
            });
        }, [adImageId, contractGuid]);
    const renderRow = ({header, width, height, colorType, isUploadRow}) => {
        const validColorspace = _.lowerCase(imageProperties?.colorType || "").includes("cmyk");
        return <tr style={{
            backgroundColor: isUploadRow ? lightGreen : undefined,
            fontWeight: isUploadRow || (fitsResolutionRequirement(matchedResolution || [], [width, height])) ? "bold" : undefined
        }}>
            <td className={isUploadRow ? "emphasize" : undefined}>{header}</td>
            <td>
                {_.round(width, 0)}px X {_.round(height, 0)}px
                <div style={{fontWeight: "initial"}}>
                    [
                    {isUploadRow && matchedResolution ?
                        <>
                            {_.round(getMmFromPxDpi(matchedResolution[0] / 10), 2)}cm
                            X {_.round(getMmFromPxDpi(matchedResolution[1] / 10), 2)}cm
                            at {_.round(width / matchedResolution[0] * 300)} DPI
                        </>
                        : <>
                            {_.round(getMmFromPxDpi(width), 1) / 10}cm X {_.round(getMmFromPxDpi(height), 1) / 10}cm at
                            300 DPI
                        </>}
                    ]
                </div>
                {isUploadRow && !matchedResolution && <div>
                    <div style={{color: isUploadRow ? (matchedResolution ? "blue" : "red") : undefined}}>Invalid Ad
                        Size
                    </div>
                    <div style={{fontWeight: "initial"}}>
                        Please upload a version of the ad that matches the required size as per the table above
                    </div>
                </div>}
            </td>
            <td>
                <div>{colorType}</div>
                {isUploadRow && !validColorspace && <div>
                    <div style={{color: isUploadRow ? (validColorspace ? "blue" : "red") : undefined}}>
                        Invalid Color Type
                    </div>
                    <div style={{fontWeight: "initial"}}>Please have your graphics designer send you a CMYK version of
                        the ad
                    </div>
                </div>}
            </td>
        </tr>;
    }

    return (
        <div style={{height: adImageId ? "90vh" : undefined, width: "90vw", display: "flex", flexDirection: "column"}}>
            {adImageId ?
                <>
                    <ImageDisplay contractGuid={contractGuid} contractLineItem={contractLineItem}/>
                    <div style={{borderTop: `1px solid ${darkPurple}`}}>
                        {
                            <Button disabled={approved} onClick={handleDelete} style={{
                                margin: '10px 0 10px 10px',
                                width: 'calc(50% - 15px)',
                            }}>{approved ? "Approved" : "Delete"}</Button>
                        }
                        <Button onClick={closeModal} style={{
                            margin: '10px',
                            width: 'calc(50% - 15px)',
                        }}>Close</Button>
                    </div>
                </> :
                <Formik
                        initialValues={{
                            ad_image: undefined,
                        }}
                        onSubmit={async (values) => {
                            console.log(uploadRef.current, values);
                            if (!imageProperties) {
                                toast.warn("Uploading unverified image")
                            }
                            try {
                                // const ret = await uploadRef.current.processFiles();
                                const ret = await uploadQ.mutateAsync(values)
                                console.log(ret)
                                queryClient.invalidateQueries(["getContract", contractGuid]);
                                toast.success("Successfully uploaded Ad");
                                closeModal();
                            } catch (e) {
                                toast.error("Error uploading Ad");
                                console.log(e);
                            }
                        }}
                    >
                        {({values, handleSubmit}) => (
                            <>
                                <div style={{
                                    marginTop: '10px',
                                    textAlign: 'center',
                                    color: darkGreen,
                                    fontWeight: 'bold'
                                }}>
                                    Upload Advertisement Image
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    Image must have {_.join(_.map(resolutions, r => `a width of ${r[2].w / 10}cm, a height
                            of ${r[2].h / 10}cm`), " or ")} with <i>at least</i> 300 DPI, and be in CMYK format
                                </div>
                                <SpecificationTable>
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Size</th>
                                        <th>Color Components</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {resolutions.map((r, i) => renderRow({
                                        header: `${type}${resolutions.length > 1 ? ` [${i === 0 ? "Horizontal" : "Vertical"}]` : ""}`,
                                        width: r[0],
                                        height: r[1],
                                        colorType: "CMYK"
                                    }))}
                                    {imageProperties && renderRow({
                                        header: "Upload",
                                        isUploadRow: true, ...(imageProperties || {})
                                    })}
                                    </tbody>
                                </SpecificationTable>
                                <div style={{padding: '10px', overflow: 'auto'}}>
                                    <FilePondField
                                        name={'ad_image'}
                                        maxFiles={1}
                                        // imageValidateSizeMinWidth={_.minBy(resolutions, r => r[0])[0]}
                                        // imageValidateSizeMaxWidth={_.maxBy(resolutions, r => r[0])[0]}
                                        // imageValidateSizeMinHeight={_.minBy(resolutions, r => r[1])[1]}
                                        // imageValidateSizeMaxHeight={_.maxBy(resolutions, r => r[1])[1]}
                                        // server={{url: `${apiUrl}/UploadAd/${id}`}}
                                        instantUpload={false}
                                        updateFilesHandler={async files => {
                                            const file = _.first(files);
                                            const info = await getImageMetaFromFile(file)
                                            setImageProperties(info)
                                            console.log("metadata", info)
                                        }}
                                        ref={uploadRef}
                                    />
                                </div>


                                <div>
                                    <Button onClick={handleSubmit}
                                            disabled={!matchedResolution || imageProperties?.colorType !== "CMYK"}
                                            style={{
                                                margin: '10px 0 10px 10px',
                                                width: 'calc(50% - 15px)',
                                            }}>Save</Button>
                                    <Button onClick={closeModal} style={{
                                        margin: '10px',
                                        width: 'calc(50% - 15px)',
                                    }}>Close</Button>
                                </div>
                            </>
                        )}
                    </Formik>
                }
            </div>
        )
            ;
    }
;

const getImageMetaFromFile = async file => {
    let ret = undefined;
    if (file) {
        const buffer = await file.arrayBuffer?.();
        if (!buffer) return ret;
        console.log(file, buffer)
        const extension = _.toLower(_.last(_.split(file?.name, '.')));
        const isPDF = extension === "pdf";
        if (isPDF) {
            const images = await getPDFImages(buffer)
            const image = _.first(images)
            ret = image
            console.log(images, file)
            if (images.length > 1) {
                toast.error("PDF contains more than 1 image")
            } else if (!image) {
                toast.error("PDF doesnt contain extractable image")
            }
        } else if (["jpg", "jpeg"].includes(extension)) {
            const metadata = ExifReader.load(buffer, {expanded: true})
            const file = metadata.file
            const {
                'Color Components': {value: colorComponents} = {},
                'Image Height': {value: height} = {},
                'Image Width': {value: width} = {},
            } = file || {};
            console.log(metadata, file)

            let colorType = 'Unknown';
            switch (colorComponents) {
                case 3:
                    colorType = 'RGB';
                    break
                case 4:
                    colorType = 'CMYK';
                    break
            }
            ret = {colorType, width, height}
        } else if (["png"].includes(extension)) {
            const metadata = ExifReader.load(buffer, {expanded: true})
            const file = metadata.pngFile
            const {
                'Color Type': {description: colorType} = {},
                'Image Height': {value: height} = {},
                'Image Width': {value: width} = {},
            } = file || {};
            console.log(metadata, file)

            ret = {colorType, width, height}
        }
    }
    return ret;
}

//Based on https://github.com/Hopding/pdf-lib/issues/83#issuecomment-487383843 
const getPDFImages = async (buffer) => {
    const pdfDoc = await PDFDocument.load(buffer);
    console.log(pdfDoc)

    // Define some variables we'll use in a moment
    const imagesInDoc = [];
    let objectIdx = 0;
    // (1) Find all the image objects in the PDF
    // (2) Extract useful info from them
    // (3) Push this info object to `imageInDoc` array
    pdfDoc.context.enumerateIndirectObjects().forEach(([ref, pdfObject]) => {
        objectIdx += 1;
        if (!(pdfObject instanceof PDFRawStream)) return;
        const {dict} = pdfObject;
        const simplifiedDict = _.fromPairs(_.map(dict.entries(), ([k, v]) => [k.value(), v]));
        const smaskRef = simplifiedDict['/SMask'];
        const colorSpace = simplifiedDict['/ColorSpace']?.value?.();
        const subtype = simplifiedDict['/Subtype'];
        const width = simplifiedDict['/Width'];
        const height = simplifiedDict['/Height'];
        const name = simplifiedDict['/Name'];
        const bitsPerComponent = simplifiedDict['/BitsPerComponent'];
        const filter = simplifiedDict['/Filter'];
        if (subtype?.value() === '/Image') {
            console.log(dict, simplifiedDict, subtype,
                {
                    smaskRef,
                    colorSpace,
                    subtype,
                    width,
                    height,
                    name,
                    bitsPerComponent,
                    filter
                })
            imagesInDoc.push({
                ref,
                smaskRef,
                colorSpace,
                colorType: colorSpace?.includes("RGB") ? "RGB" : colorSpace?.includes("CMYK") ? "CMYK" : colorSpace,
                name: name ? name.key : `Object${objectIdx}`,
                width: width?.numberValue,
                height: height?.numberValue,
                bitsPerComponent: bitsPerComponent?.numberValue,
                data: pdfObject.content,
                type: filter?.value() === '/DCTDecode' ? 'jpg' : 'png',
            });
        }
    });
    return imagesInDoc;
}
