import {Auth} from "aws-amplify";
import _ from "lodash";

const isDev = process.env.NODE_ENV !== 'production';
export const apiUrl = isDev ? 'https://localhost:5011' : 'https://api.newcomersguide.co.il';

const formContentType = `multipart/form-data`;

const fetchData/*: ({url, data, method, contentType}: { url: string; data?: FormDataNest; method?: any; contentType?: any }) => Promise<any>*/ =
    async ({
               url,
               data = undefined,
               method = 'GET',
               contentType = 'application/json',
           }) => {
        const isGetRequest = method === 'GET';

        const headers = {};
        try {
            const session = await Auth.currentSession();
            const token = session?.accessToken?.jwtToken;
            if (token) {
                headers.Authorization = `Bearer ${token}`;
            }
        } catch (e) {
            //Ignore error
        }

        let body = undefined;
        if (!_.isNil(data)) {
            if (isGetRequest) {
                data = _.omitBy(data, p => p === undefined);
                let params = new URLSearchParams(data /*as undefined*/).toString();
                url = `${url}?${params}`;
                headers['Content-Type'] = contentType;
            } else if (contentType === formContentType) {
                body = getFormData(data);
            } else if (contentType === "blob") {

            } else {
                body = JSON.stringify(data);
                headers['Content-Type'] = contentType;
            }
        }

        let ret = await fetch(`${apiUrl}${url}`,
            {
                method: method,
                body: body,
                headers,
                timeout: 5 * 60 * 1000,
            });
        if (!ret.ok) {
            throw await ret.text();
        }

        if (contentType === "blob") {
            return URL.createObjectURL(await ret.blob());
        } else {
            return await ret.json();
        }

    };

//From https://stackoverflow.com/a/64527862/5683904
const buildFormData = (formData, data, parentKey) => {
    if (data == null) data = undefined;
    if (Array.isArray(data)) {
        if (data.length) {

            data.forEach((el) => {
                buildFormData(formData, el, parentKey);
            });
        } else {
            formData.append(parentKey, []);
        }

    } else if (typeof data === "object" && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, (data)[key], parentKey ? `${parentKey}.${key}` : key);
        });

    } else {
        let value = typeof data === "boolean" || typeof data === "number" ? data.toString() : data;
        formData.append(parentKey, value ?? "");
    }
};

export const getFormData = (data) => {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
};

export const getContract = (id) => fetchData({url: `/GetContract/${id}`});
export const getPaymentPageCode = (id) => fetchData({url: `/GetPaymentPageCode/${id}`});
export const getPaymentStatus = (id) => fetchData({url: `/CheckIfContractWasPaid/${id}`});
export const getAdminMasterLists = () => fetchData({url: "/Admin/GetAdminMasterLists"});
export const getUpload = (contractId, adId) => fetchData({
    url: `/GetUpload/${contractId}/${adId}`,
    contentType: "blob"
});
export const signContract = ({contractGuid, name, title, date}) => fetchData({
    url: `/SignContract/`,
    method: 'POST',
    data: {contractGuid, name, title, date}
});
export const sendContractLink = ({contractId, message}) => fetchData({
    url: `/Admin/SendContractLink/${contractId}`,
    method: 'POST',
    data: message
});
export const toggleAllowAlternativePaymentPlan = ({contractId}) => fetchData({
    url: `/Admin/ToggleAllowAlternativePaymentPlan/${contractId}`,
    method: 'POST'
});
export const deleteAd = ({contractGuid, adImageId}) => fetchData({
    url: `/DeleteAd/${contractGuid}/${adImageId}`,
    method: "POST"
});
export const uploadAd = ({contractDetailId, data}) => fetchData({
    url: `/UploadAd/${contractDetailId}`,
    method: "POST",
    contentType: formContentType,
    data
});
export const updateTashlumim = ({contractId, amount}) => fetchData({
    url: `/Admin/UpdateTashlumim/${contractId}`,
    method: 'POST',
    data: amount
});

export const rejectAd = ({adImageId, message}) => fetchData({
    url: `/Admin/RejectAd/${adImageId}`,
    method: 'POST',
    data: message
});

export const updateAdApprovalState = ({adImageId, approvalStatus}) => fetchData({
    url: `/Admin/ApproveAd/${adImageId}`,
    method: 'POST',
    data: approvalStatus
});