import React, {useCallback, useContext, useState} from "react";
import {AppFunctionsContext} from "../App";
import {useMutation, useQueryClient} from "react-query";
import {sendContractLink} from "../functions/api";
import {toastMessages} from "../hooks/useToastMessages";
import {InnerCellWrapper} from "../Components/ReactTable";
import _ from "lodash";
import {Button} from "../Components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as far from "@fortawesome/pro-regular-svg-icons";
import * as fas from "@fortawesome/pro-solid-svg-icons";

const sendEmailOverlay = 'send-email-overlay'
export const EmailModal = ({contract, client}) => {
    const {addToOverlaySet, removeFromOverlaySet, closeModal} = useContext(AppFunctionsContext);
    const queryClient = useQueryClient();
    const sendContractLinkQuery = useMutation({mutationFn: sendContractLink, mutationKey: "sendContractLink"});
    const [message, setMessage] = useState(`Hi ${client.contactName},

As per our conversation, below is a link to view the contract for your ad in the upcoming Newcomers Guide. 
Please look over the contract to make sure the details are correct, confirm it and pay for the ad. 
You will see a place to upload your ad as soon as it's ready. Ads are due by July 4. 

Click <a href="https://portal.newcomersguide.co.il/contracts/${contract.guid}">here</a> to view your contract.

Please keep this link private - although the link is unique and unguessable, it is only as safe as you keep it - anyone with this link can view the contract. 

If you have any questions or comments, please contacts us at ads@newcomersguide.co.il or call us at 1-599-500-605
All the best,
${(contract.agent ? `${contract.agent} - ` : "")}The Newcomers Guide`)

    const handleSendEmail = useCallback(async () => {
        try {
            addToOverlaySet(sendEmailOverlay);
            const ret = await sendContractLinkQuery.mutateAsync({contractId: contract.id, message});

            const {
                messageArray,
            } = ret || {};

            toastMessages(messageArray, () => queryClient.invalidateQueries(["getAdminMasterLists"]));
        } finally {
            removeFromOverlaySet(sendEmailOverlay);
        }
    }, [contract.id, message])

    return (
        <div style={{height: '80vh', width: '80vw', display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{
                flexGrow: 1,
                width: "100%",
                margin: 6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <textarea value={message} onChange={e => setMessage(e.target.value)}
                          style={{width: "95%", height: "95%", border: "1px black solid"}}/>
            </div>
            <div style={{display: "flex", flexDirection: "row", margin: 10}}>
                <Button style={{flexGrow: 1, margin: 6}} onClick={handleSendEmail}>Send</Button><Button
                style={{flexGrow: 1, margin: 6}} onClick={closeModal}>Cancel</Button>
            </div>
        </div>
    );
};
export const EmailCell = cell => {
    const {openModal} = useContext(AppFunctionsContext);
    const {row: {original: contract = {}} = {}, clientLookup} = cell;
    const client = clientLookup[contract.clientId];
    const openEmailModal = useCallback(() => {
        openModal({
            header: 'Message Editor',
            content: <EmailModal contract={contract} client={client}/>,
        });
    }, [contract, client]);


    return <InnerCellWrapper>{_.isNil(client?.contactEmail) ?
        <span>No Email</span> :
        <>
            <Button
                onClick={openEmailModal}
            >
                {contract.lastSentLink ?
                    <>
                        <FontAwesomeIcon icon={far.faPaperPlane}/>{" "}
                        Resend Link
                    </> :
                    <>
                        <FontAwesomeIcon icon={fas.faPaperPlane}/>{" "}
                        Send Link
                    </>
                }
            </Button>
        </>}
    </InnerCellWrapper>;

}
