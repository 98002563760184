import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import _ from 'lodash';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getAdminMasterLists, toggleAllowAlternativePaymentPlan, updateTashlumim} from "../functions/api";
import Table, {InnerCellWrapper, SelectColumnFilter} from "../Components/ReactTable";
import {AppFunctionsContext} from "../App";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {ilsPriceDiv} from "../functions/shared";
import {
    darkGreen,
    darkTeal,
    IconButtonDiv,
    mediumPurple,
    PageTitle,
    veryDarkGreen,
    veryDarkPurple,
} from "../Components/BaseComponents";
import Measure from "react-measure";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import * as fal from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StyledSelect} from "../Components/Input";
import {toastMessages} from "../hooks/useToastMessages";
import {EmailCell} from "./EmailModal";
import {ContractDetailsModal} from "./ContractDetailsModal";
import {adTypeSizesLookup} from "../functions/data";
import ReactSwitch from "react-switch";
import {toast} from "react-toastify";

const loadingAdminOverlayKey = 'loading-admin';
const savingAdminOverlayKey = 'saving-admin';

const tashlumimOptions = _.range(1, 13).map(x => ({value: x, label: x}));

const TashlumimCell = cell => {
    const {addToOverlaySet, removeFromOverlaySet} = useContext(AppFunctionsContext);
    const queryClient = useQueryClient();

    const {row: {original: contract = {}} = {}} = cell || {};
    const {id: contractId, paid, payments} = contract || {};

    const updateTashlumimQuery = useMutation({
        mutationFn: (props) =>
            updateTashlumim(props), mutationKey: "updateTashlumim",
    });

    const handleChange = useCallback(async ({value}) => {
        try {
            addToOverlaySet(savingAdminOverlayKey);
            const ret = await updateTashlumimQuery.mutateAsync({contractId: contractId, amount: value});

            const {
                messageArray,
            } = ret || {};

            toastMessages(messageArray, () => queryClient.invalidateQueries(["getAdminMasterLists"]));
        } finally {
            removeFromOverlaySet(savingAdminOverlayKey);
        }
    }, []);

    if (paid) {
        return <InnerCellWrapper>{payments || 1}</InnerCellWrapper>;
    }

    return (
        <InnerCellWrapper>
            <StyledSelect
                value={tashlumimOptions.find(x => x.value === (cell.value || 1))}
                options={tashlumimOptions}
                onChange={handleChange}
                styles={{
                    indicatorSeparator: base => ({
                        ...base,
                        background: 'none',
                    }),
                }}
            />
        </InnerCellWrapper>
    );
};

const DetailsCell = cell => {
    const {openModal} = useContext(AppFunctionsContext);

    const {row: {original: contract = {}} = {}, contractDetailsLookup} = cell || {};
    const contractDetails = contractDetailsLookup[contract.id];
    const status = _.reduce(contractDetails, (acc, value) => {
        const {
            type,
            adImageId,
            approved,
        } = value;

        const sizeInfo = adTypeSizesLookup[type];

        if (sizeInfo && adImageId) {
            if (approved) {
                if (acc !== 'draft') acc = 'approved';
            } else {
                acc = 'draft';
            }
        }

        return acc;
    }, undefined);

    const openDetailsModal = useCallback(() => {
        openModal({
            header: 'Contract Details',
            content: <ContractDetailsModal contract={contract} contractDetails={contractDetailsLookup[contract.id]}/>,
        });
    }, [contract, contractDetailsLookup]);

    return (
        <InnerCellWrapper>
            <IconButtonDiv
                color={status ? (status === 'approved') ? darkGreen : mediumPurple : '#aaaaaa'}
                hoverColor={status ? (status === 'approved') ? veryDarkGreen : veryDarkPurple : '#888888'}
                onClick={openDetailsModal}
            >
                <FontAwesomeIcon icon={fas.faFileContract}/>
            </IconButtonDiv>
        </InnerCellWrapper>
    );
};

export const Admin = () => {
    const {addToOverlaySet, removeFromOverlaySet} = useContext(AppFunctionsContext);
    const [tableWrapperHeight, setTableWrapperHeight] = useState();

    const adminMasterListsQuery = useQuery({queryFn: () => getAdminMasterLists(), queryKey: ["getAdminMasterLists"]});
    const toggleAllowAlternatePlansQ = useMutation({
        mutationKey: "toggleAllowAlternativePaymentPlan",
        mutationFn: (props) => toggleAllowAlternativePaymentPlan(props),
    });

    useEffect(() => {
        if (adminMasterListsQuery.isLoading) {
            addToOverlaySet(loadingAdminOverlayKey);
        } else {
            removeFromOverlaySet(loadingAdminOverlayKey);
        }
    }, [adminMasterListsQuery.isLoading]);

    const {
        data,
        success,
        messageArray,
    } = adminMasterListsQuery?.data ?? {};

    const {
        clients,
        contracts,
        contractDetailsList,
    } = data ?? {};

    const clientLookup = useMemo(() => _.keyBy(clients, 'id'), [clients]);
    const contractDetailsLookup = useMemo(() => _.groupBy(contractDetailsList, 'contractId'), [contractDetailsList]);
    const queryClient = useQueryClient();
    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: c => clientLookup[c.clientId]?.ncgClientNumber || "e" + c.externalId, //Use ncgClientNumber, since that might be meaningful when compared to their actual program
            width: 70,
        },
        {
            Header: 'Agent',
            id: 'agent',
            accessor: c => c.agent,
            Filter: SelectColumnFilter,
            disableGroupBy: false,
        },
        {
            Header: 'Client',
            id: 'client',
            accessor: c => clientLookup[c.clientId]?.name,
            width: 150,
        },
        //Should we show this?
        // {
        //     Header: 'Contact',
        //     id: 'contact',
        //     accessor: c => {
        //         const client = clientLookup[c.clientId];
        //         if (!client) return '';
        //         return (client.contactName || client.billingName) + " - " + (client.contactPhone || client.billingPhone);
        //     },
        // },
        {
            Header: 'Date',
            id: 'date',
            accessor: 'date',
            Cell: c => <InnerCellWrapper>{dayjs(c.value).format("DD MMM YYYY")}</InnerCellWrapper>,
            disableFilters: true,
        },
        {
            Header: 'Total',
            id: 'total',
            accessor: 'total',
            Cell: c => <InnerCellWrapper>{ilsPriceDiv(c.value)}</InnerCellWrapper>,
            disableFilters: true,
            width: 100,
        },
        {
            Header: 'Comment',
            accessor: 'comment',
            width: 200,
        },
        {
            Header: 'Signed',
            id: 'signed',
            accessor: r => r.signed ?? r.paid, //Anyone who has paid is considered to have signed
            Cell: c => <InnerCellWrapper>{c.value ? dayjs(c.value).format("DD MMM YYYY") :
                <div style={{opacity: 0.5}}>Not Signed</div>}</InnerCellWrapper>,
            disableFilters: true,
        },
        {
            Header: 'Paid',
            id: 'paid',
            accessor: 'paid',
            Cell: c => <InnerCellWrapper>{c.value ? dayjs(c.value).format("DD MMM YYYY") :
                <div style={{opacity: 0.5}}>Not Paid</div>}</InnerCellWrapper>,
            disableFilters: true,
        },
        {
            Header: 'Tashlumim',
            id: 'payments',
            accessor: 'payments',
            Cell: TashlumimCell,
            disableFilters: true,
        },
        {
            Header: 'Details',
            Cell: DetailsCell,
            width: 70,
            disableFilters: true,
        },
        {
            Header: 'Link',
            id: 'guid',
            accessor: 'guid',
            Cell: c => {
                const [copied, setCopied] = useState(false);
                return <InnerCellWrapper>
                    <Link to={`/contracts/${c.value}`} target={"_blank"}>
                        Link
                    </Link>
                    <IconButtonDiv onClick={async () => {
                        await navigator.clipboard.writeText(`https://portal.newcomersguide.co.il/contracts/${c.value}`);
                        setCopied(true);
                        toast.success("Link Copied to Clipboard");
                        setTimeout(() => {
                            setCopied(false);
                        }, 1000);
                    }} style={{marginLeft: '10px'}}>
                        <FontAwesomeIcon icon={!copied ? fal.faClipboard : fas.faClipboard}/>
                    </IconButtonDiv>
                </InnerCellWrapper>;
            },
            width: 60,
            disableFilters: true,
        },
        {
            Header: 'Notify',
            id: 'notify',
            width: 160,
            accessor: 'notify',
            Cell: EmailCell,
            disableFilters: true,
        },
        {
            Header: 'Link Last Sent',
            id: 'lastSentLink',
            width: 160,
            accessor: 'lastSentLink',
            Cell: ({value}) => <div style={{display: 'flex', justifyContent: 'center'}}>
                {value ? dayjs(value).format("DD MMM YYYY") : null}
            </div>,
            disableFilters: true,
        },
        {
            Header: 'Allow Alternative Payment',
            id: 'allowAlternativePaymentPlan',
            accessor: 'allowAlternativePaymentPlan',
            width: 160,
            Cell: c => {
                const contractId = c.row?.original?.id;
                return <InnerCellWrapper>
                    <ReactSwitch checked={c.value} onChange={async (value) => {
                        console.log({c, value, contractId});
                        const overlay = "toggle-alternative";
                        try {

                            addToOverlaySet(overlay);
                            const ret = await toggleAllowAlternatePlansQ.mutateAsync({contractId});

                            const {
                                messageArray,
                                data,
                            } = ret || {};

                            queryClient.setQueryData(["getAdminMasterLists"], r => {
                                const {data: {contracts} = {}} = r || {};
                                if (!contracts) return r;
                                const cur = _.find(contracts, c => c.id === contractId);
                                if (cur) {
                                    cur.allowAlternativePaymentPlan = data;
                                }
                                r.data.contracts = [...r.data.contracts];
                                return r;
                            });

                            toastMessages(messageArray);
                        } finally {
                            removeFromOverlaySet(overlay);
                        }
                    }}/>
                </InnerCellWrapper>;
            },
            disableFilters: true,
        },
    ], [clientLookup, contractDetailsLookup]);

    if (adminMasterListsQuery.isLoading) {
        return <></>;
    }

    if (_.isEmpty(contracts)) {
        return (
            <div style={{color: 'blue', fontWeight: 'bold', fontSize: '20px', margin: '20px', textAlign: 'center'}}>
                No Contracts Found!
            </div>
        );
    }

    return (
        <div style={{
            padding: '10px',
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            height: 'calc(100% - 20px)',
        }}>
            <PageTitle style={{marginBottom: '10px'}}>Contracts</PageTitle>
            <Measure bounds onResize={contentRect => {
                setTableWrapperHeight(contentRect.bounds.height);
            }}>{({measureRef}) => (
                <div ref={measureRef} style={{height: '100%'}}>
                    <div style={{border: `2px solid ${darkTeal}`}}>
                        <Table
                            columns={columns}
                            data={contracts}
                            contractDetailsLookup={contractDetailsLookup}
                            clientLookup={clientLookup}
                            rowSize={45}
                            maxHeight={tableWrapperHeight}
                        />
                    </div>
                </div>
            )}</Measure>
        </div>
    );
};
