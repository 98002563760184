import {useState, useEffect, useRef} from "react";

//See: https://stackoverflow.com/a/58439475/5515589
export const useStateRef = initialValue => {
    const [value, setValue] = useState(initialValue);

    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return [value, setValue, ref];
};