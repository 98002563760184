import React from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router";
import _ from "lodash";
import {brightRed, darkGreen} from "../Components/BaseComponents";
import styled from "styled-components";
import {getPaymentStatus} from "../functions/api";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
`

export const PaymentStatus = () => {
    const {id: contractGuid} = useParams();

    //paymentStatusQ returns true if it was paid, otherwise no charge was made
    const paymentStatusQ = useQuery({
        queryFn: () => getPaymentStatus(contractGuid),
        queryKey: ["getPaymentStatus", contractGuid],
    });

    const {data, isLoading, isLoadingError} = paymentStatusQ;

    if (isLoading) return "Loading...";
    if (isLoadingError) return "Error";

    return (
        <Wrapper>
            <div>
                Payment Status
            </div>
            {data.data ?
                <div style={{color: darkGreen, fontWeight: "bold"}}>Success</div> :
                <>
                    <div style={{color: brightRed, fontWeight: "bold"}}>Error</div>
                    {_.map(data?.messageArray, m => <div style={{color: brightRed}}>{m?.message}</div>)}
                </>
            }
            <div>
                You can now safely close the modal
            </div>
        </Wrapper>
    );
};