import React, {forwardRef, useRef} from "react";
import styled from "styled-components";
import Select from "react-select";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {ErrorMessage, Field, useFormikContext} from "formik";
import {darkGreen, darkTeal, mediumTeal} from "./BaseComponents";
import {useTranslation} from "react-i18next";

const InputOuterWrapper = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &:focus-within label {
    transform: translate(0, 14px) scale(0.8);
  }

  & label.filled {
    transform: translate(0, 14px) scale(0.8);
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top ${({isRtl}) => isRtl ? 'right' : 'left'};
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${darkGreen};
  font-size: 16px;
  line-height: 1;
  top: -8px;
  padding: ${({isRtl}) => isRtl ? '0 14px 0 5px' : '0 5px 0 14px'};
  z-index: 1;
`;

const StyledField = styled(Field)`
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 16px;
  border: 1px solid ${darkGreen};
  border-radius: 5px;
  padding: 20px 10px 6px 10px;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &:focus {
    box-shadow: 0 0 0 2px ${darkGreen};
  }

  &.error {
    border-color: red;
    background: #ffeeee;
  }
`;

const ErrorDiv = styled.div`
  color: red;
`;

const UnderFieldLabel = styled.div`
  font-size: 16px;
  color: ${darkTeal};
`;

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImageValidateSize);
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    // "image/png",  //PNG does not support CMYK
    "image/tiff",
    "application/pdf",
];

export const FilePondField = forwardRef(({label, name, maxFiles, updateFilesHandler, ...filePondProps}, ref) => {
    const {setFieldValue, values} = useFormikContext();
    const allowMultiple = maxFiles > 1;

    const fileRef = useRef();

    return <div>
        <label>{label}</label>
        <FilePond
            ref={ref}
            name={name}
            allowMultiple={allowMultiple}
            maxFiles={maxFiles}
            files={fileRef.current}
            onupdatefiles={fileItems => {
                const files = fileItems.map(f => f.file);
                fileRef.current = files;
                setFieldValue(name, files);
                updateFilesHandler(files)
            }}
            maxFileSize={"20MB"}
            acceptedFileTypes={SUPPORTED_FORMATS}
            labelFileTypeNotAllowed={'Invalid file type'}
            {...filePondProps}
        >
        </FilePond>
    </div>;
});

export const StyledSelect = styled(Select).attrs(({styles = {}} = {}) => ({
    classNamePrefix: 'react-select',
    styles: {
        ...styles,
        control: base => ({
            ...base,
            cursor: 'pointer',
            border: `1px solid ${darkTeal}`,
            ...styles.control?.(base),
        }),
        input: base => ({
            // ...base,
            ...styles.singleValue?.(base),
        }),
        singleValue: base => ({
            // ...base,
            ...styles.singleValue?.(base),
        }),
        option: base => ({
            ...base,
            cursor: 'pointer',
            ...styles.option?.(base),
        }),
        menuPortal: base => ({
            ...base,
            zIndex: 10050,
            ...styles.menuPortal?.(base),
        }),
    },
    menuPortalTarget: document.body,
    menuPosition: 'fixed',
}))`
  &.error > div {
    border-color: red;
    background: #ffeeee;
  }

  & .react-select__control--is-focused {
    box-shadow: 0 0 0 2px ${mediumTeal};
  }

  & .react-select__control:hover {
    border-color: ${darkTeal};
  }
`;

export const InputField = ({label, underFieldLabel, type = 'text', name}) => {
    const {
        values,
        errors,
        touched,
    } = useFormikContext();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    const hasValue = !!values?.[name];
    const hasError = !!errors?.[name] && !!touched?.[name];

    return (
        <InputOuterWrapper>
            <InputWrapper>
                <StyledLabel isRtl={isRtl} className={hasValue && 'filled'}>{label}</StyledLabel>
                <StyledField type={type} name={name} className={hasError && 'error'}/>
                <UnderFieldLabel>{underFieldLabel}</UnderFieldLabel>
                <ErrorMessage name={name} component={ErrorDiv}/>
            </InputWrapper>
        </InputOuterWrapper>
    );
};