import React, {useContext} from "react";
import styled from "styled-components";
import * as yup from 'yup';
import {Formik} from 'formik';
import {Button} from "../Components/Button";
import {AppFunctionsContext} from "../App";
import {toast} from 'react-toastify';
import {Auth} from "aws-amplify";
import {Redirect, useHistory} from "react-router";
import {useAuth} from "../hooks/useAuth";
import {darkGreen, Heading} from "../Components/BaseComponents";
import {InputField} from "../Components/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from "@fortawesome/pro-solid-svg-icons";

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  border: 1px solid ${darkGreen};
  border-radius: 10px;
  background: #ffffff80;
  padding: 50px;
`;

const FieldWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const loginSchema = yup.object().shape({
    email: yup.string().required('Required'),
    password: yup.string().required('Required'),
});

export const Login = () => {
    const {addToOverlaySet, removeFromOverlaySet} = useContext(AppFunctionsContext);
    const history = useHistory();
    const {loggedIn} = useAuth();

    const handleSubmit = async values => {
        console.log(values);
        const overlayId = 'login-submit';
        addToOverlaySet(overlayId);
        const {email, password} = values || {};

        try {
            const ret = await Auth.signIn(email, password);
            if (ret.challengeName === "NEW_PASSWORD_REQUIRED") {
                // The following line can be used to temporarily override the change password flow
                await Auth.completeNewPassword(ret, password);
                removeFromOverlaySet(overlayId);
                // history.push('/password/change', {email, password});
            }
            history.push('/admin', {email, password});
        } catch (e) {
            toast.error(e.message);
        } finally {
            removeFromOverlaySet(overlayId);
        }
    };

    if (loggedIn) return <Redirect to={"/admin"}/>;

    return (
        <Formik
            initialValues={{email: '', password: ''}}
            onSubmit={handleSubmit}
            validationSchema={loginSchema}
        >{({isSubmitting, handleSubmit}) => (
            <StyledForm>
                <StyledInnerWrapper>
                    <Heading><FontAwesomeIcon icon={fas.faLock}/></Heading>
                    <Heading>Sign in to your account</Heading>
                    <FieldWrapper>
                        <InputField name={'email'} type={'text'} label={'Login'}/>
                    </FieldWrapper>
                    <FieldWrapper>
                        <InputField name={'password'} type={'password'} label={'Password'}/>
                    </FieldWrapper>
                    <FieldWrapper style={{textAlign: 'center'}}>
                        <Button type={'submit'} onClick={handleSubmit} disabled={isSubmitting} style={{width: '200px'}}>
                            Login
                        </Button>
                    </FieldWrapper>
                </StyledInnerWrapper>
            </StyledForm>
        )}
        </Formik>
    );
};