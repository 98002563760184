export const businessCategoryList = [
    "Accounting & Taxes",
    "Advertising",
    "Air Conditioning & Heating",
    "Alarm Systems",
    "Aliyah",
    "Alternative Medicine",
    "Aluminum & Iron Work",
    "Amplifiers",
    "Appliance Repairs",
    "Appliances",
    "Architecture",
    "Art Classes",
    "Artwork & Wall Hangings",
    "Baby Supplies",
    "Badchanim",
    "Bakeries",
    "Baking Supplies",
    "Balloons",
    "Banks",
    "Barbers",
    "Beauticians & Cosmetics",
    "Bedding & Linen",
    "Benchers",
    "Blinds & Trissim",
    "Books & Seforim",
    "Boxes",
    "Bridal Gowns",
    "Bridal Hair & Makeup",
    "Bridal Headpieces",
    "Builder",
    "Bug Checking Courses",
    "Bureaucratic Assistance",
    "Burial Monuments",
    "Business Consulting",
    "Butchers",
    "Cakes, Cookies & Miniatures",
    "Camps",
    "Candy Stores",
    "Car Leasing & Sales",
    "Car Rentals",
    "Carpet and Upholstery Care",
    "Catering",
    "CD/DVD Duplication",
    "Cell Phone Rentals",
    "Childbirth Support & Classes",
    "Chiropractors",
    "Chocolates",
    "Chugim",
    "Cleaning Services",
    "Clinics",
    "Clothing, Accessories",
    "Clothing, Baby and Children",
    "Clothing, Eveningwear",
    "Clothing, Maternity",
    "Clothing, Men",
    "Clothing, Shells",
    "Clothing, Women",
    "Coats",
    "Color Analysis",
    "Computer Sales & Rentals",
    "Computer Services",
    "Computer Courses",
    "Contractor",
    "Curtains",
    "Cutlery",
    "Dance Lessons",
    "Delivery Services",
    "Dentists",
    "Dishes",
    "Dishes & Tablecloths Rentals",
    "Doctors, Cardiologists",
    "Doctors, Dermatologists",
    "Doctors, ENT",
    "Doctors, Family",
    "Doctors, Neurologists",
    "Doctors, OB-GYN",
    "Doctors, Optometrists",
    "Doctors, Orthopedists",
    "Doctors, Pediatricians",
    "Doctors, Podiatrists",
    "Driving Instruction",
    "Dry Cleaning",
    "Dud Shemesh Installation",
    "Dud Shemesh Repair",
    "Ear Piercing",
    "Editing & Writing Courses",
    "Editing & Writing Services",
    "Education",
    "Eggs",
    "Electricians",
    "Electrolysis",
    "Electronics",
    "Embroidery",
    "Entertainment",
    "Errand Services",
    "Exercise & Weight Loss",
    "Extermination",
    "Fans",
    "Financial Services",
    "Fish",
    "Florists",
    "Folding Doors",
    "Framing",
    "Frozen Fruit",
    "Fruit Carving Courses",
    "Fruit Platters",
    "Fruit Stores",
    "Furniture",
    "Furniture Repair",
    "Gardening",
    "Gas Repairs",
    "Gazebos",
    "GenealogoistGenealogist",
    "Gift Boxes",
    "Gifts & Arrangements",
    "Gifts, International",
    "Gluten-Free Food",
    "Gold Stamping",
    "Gown Rentals",
    "GPS Rentals",
    "Graphic Design",
    "Graphics Courses",
    "Graphology",
    "Hairstyling",
    "Hairstyling Courses",
    "Halls, Simcha",
    "Halls, Wedding",
    "Handbags",
    "Handymen",
    "Hats & Snoods",
    "Hats, Men",
    "Health Food",
    "Home Care",
    "Home Management Courses",
    "Hosiery",
    "Hospitals free listings , options to advertise",
    "Hotels",
    "Hotlines",
    "Housewares",
    "Human Resources",
    "Ice Cream",
    "Imported Food & Wine",
    "Insulation",
    "Insurance",
    "Interior Design",
    "International Calls",
    "Investments",
    "Ironing",
    "Jewelry",
    "Judaica",
    "Kallah Teachers",
    "Kitchens",
    "Kollels",
    "Kugel",
    "Kupot Cholim free listings, options to advertise",
    "Lactation Consultants",
    "Lamination",
    "Lawyers",
    "Leather-Bound Gifts",
    "Lice Removal",
    "Lingerie",
    "Locksmiths",
    "Makeup Courses",
    "Massage",
    "Mattresses",
    "Mikvaos",
    "Mohelim paid only",
    "Mold Detection",
    "Mold Removal",
    "Money Changers",
    "Mortgages",
    "Movers",
    "Multimedia",
    "Music Lessons",
    "Music Stores",
    "Music Studios",
    "Musicians",
    "News",
    "No free Dr. listings, all must be called",
    "no free listings, only paid + ads",
    "Notaries",
    "Nurses",
    "Nursing Homes & Assisted Living",
    "Nursing Products",
    "Nutritionists",
    "Office Space",
    "Online Shopping",
    "Optical",
    "Organizers, Home & Office",
    "Organizations",
    "Orthopedics",
    "Painters",
    "Paper Goods",
    "Parenting Courses",
    "Party Planning",
    "Passport & Visa Services",
    "Pearls & Pearl Stringing",
    "Performing Arts",
    "Perfumes",
    "Pharmacies",
    "Photo Stores",
    "Photography, Events",
    "Photography, Family",
    "Plumbers",
    "Poems",
    "Pools",
    "Printers",
    "Printing and Distribution",
    "Projector Rentals",
    "Property Management",
    "Psychiatrists",
    "Psychologists",
    "Psychotherapists",
    "Publications",
    "Real Estate",
    "Reflexology",
    "Renovations",
    "Rentals, Short Term",
    "Restaurants",
    "Robes",
    "Schools, Boys",
    "Schools, Girls",
    "Schools, Special Education",
    "School and Office Supplies",
    "Scrapbooking",
    "Secretarial Services",
    "Self-Development Courses",
    "Senior Citizens Services",
    "Sewing & Alterations",
    "Shadchanim",
    "Shatnez",
    "Shipping",
    "Shiurim Free Listings + op to advertise",
    "Shoe Rentals",
    "Shoe Stores",
    "Silver",
    "Silversmiths",
    "Sleep Training",
    "Sofrim",
    "Stamps & Coins",
    "Stationery",
    "Storage",
    "Supermarkets No free listings, ads only",
    "Sushi",
    "Swimming Lessons",
    "Swimwear",
    "Table Pads",
    "Tablecloths",
    "Telephone & Internet",
    "Therapy, Art",
    "Therapy, Counseling",
    "Therapy, Evaluations",
    "Therapy, Family",
    "Therapy, Life Coaches",
    "Therapy, Occupational",
    "Therapy, Physical",
    "Therapy, Play",
    "Therapy, Social Workers",
    "Therapy, Speech",
    "Throat Cultures",
    "Tours & Tour Guides",
    "Toys & Games",
    "Translating Services",
    "Transportation, Beit Shemesh",
    "Transportation, Ben-Gurion Airport",
    "Transportation, Bnei Brak",
    "Transportation, Buses",
    "Transportation, Private",
    "Travel Agents",
    "Tutoring",
    "Typing Services",
    "Ulpans",
    "Ultrasounds",
    "Uniforms",
    "Upholstery",
    "Video Conversion",
    "Videography",
    "Vitamins",
    "Voice Lessons",
    "Waiters & Waitresses",
    "Watches",
    "Water Filters",
    "Waterproofing",
    "Web Design",
    "Wigs",
    "Wine & Drinks",
    "Writing Services",
];

export const sizes = {
    fullPagePreferred: {n: 'Full Page Preferred Placement', w: 148, h: 223},
    fullPageBusiness: {n: 'Full Page Business Directory', w: 125, h: 185},
    fullPageInfo: {n: 'Full Page Information Section', w: 125, h: 185},
    halfPageH: {n: 'Half Page Horizontal', w: 125, h: 89},
    halfPageV: {n: 'Half Page Vertical', w: 60.8, h: 185},
    quarterPage: {n: 'Quarter Page', w: 60.8, h: 89},
    eighthPage: {n: 'Eighth Page', w: 60.8, h: 41},
};

//Scraped from NCG website. Todo: Compare with list above - Double Spread option is missing
export const adSizeList = [
    "Full Page In Business Directory",
    "Full Page In Information Section",
    "Double Spread Premium Placement",
    "Full Page Premium Placement",
    "Half Page (Horizontal Or Vertical)",
    "Quarter Page",
    "Eighth Page",
];

export const adTypeSizesLookup = {
    "1\\1": sizes.fullPageBusiness,
    "Preferred 1\\1": sizes.fullPagePreferred,
    "1\\2": [sizes.halfPageH, sizes.halfPageV],
    "1\\4": sizes.quarterPage,
    "1\\8": sizes.eighthPage,
    "info section Strip": undefined,
    "Pkg of 10 strips": undefined,
    "Listing": undefined,
    "Bold Listing- 3 Lines": undefined,
    "Bold Listing 4 Lines": undefined,
    "3 1\\1": sizes.fullPageInfo,
    "Spine": undefined,
    "Inside Back": {...sizes.fullPagePreferred, n: "Inside Back"},
    "Back Cover": {...sizes.fullPagePreferred, n: "Back Cover"},
    "Front Cover": {...sizes.fullPagePreferred, n: "Front Cover"},
    "Additional Listing": undefined,
    "Early Bird Special": undefined,
    "Database": undefined,
    "Graphic Changes": undefined,
    "Rights to the ad": undefined,
    "Preferred Back 1\\1": {...sizes.fullPagePreferred, n: "Preferred Back Full Page"},
    "Preferred Back  Double spread": {...sizes.fullPagePreferred, n: "Preferred Back  Double spread"},
    "Barter": undefined,
    "Double spread": {...sizes.fullPagePreferred, n: "Double Spread"},
    "Translation": undefined,
    "Translation and Graphics": undefined,
    "Hebrew Ad": undefined,
    "Info section 1\\1": {...sizes.fullPagePreferred, n: "Info section Full Page"},
    "gift card purchase": undefined,
    "book": undefined,
    "Consulting": undefined,
    "Preferred Double spread": {...sizes.fullPagePreferred, n: "Preferred Double Spread"},
    "Discount": undefined,
    "bounced check": undefined,
    "Magnet & Flyer Insert": undefined,
    "Smartlist Listing": undefined,
}