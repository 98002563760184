import React, {useCallback, useContext} from "react";
import styled from "styled-components";
import _ from "lodash";
import {adTypeSizesLookup} from "../functions/data";
import {Button} from "../Components/Button";
import {downloadBlob, ilsPriceDiv} from "../functions/shared";
import {
    FieldContent,
    FieldTitle,
    FieldWrapper,
    IconButtonDiv,
    lightGreen,
    veryDarkTeal,
    veryLightGreen,
} from "../Components/BaseComponents";
import {maamPercentage} from "./Contract";
import {useTranslation} from "react-i18next";
import {UploadModal} from "./UploadModal";
import {AppFunctionsContext} from "../App";
import {getUpload} from "../functions/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import {useQueryClient} from "react-query";

export const StyledAdDetailsTable = styled.table`
  border-collapse: collapse;
  text-align: center;

  th, td {
    padding: 5px 10px;
  }

  th {
    color: white;
    background: ${veryDarkTeal};
    border-top: 1px solid ${veryDarkTeal};
  }

  th,
  tr.last-row td,
  tr.show-one-row td {
    border-bottom: 1px solid ${veryDarkTeal};
  }

  th:first-child,
  tr:not(.show-one-row, .subtotal-row, .final-total-row) td:first-child {
    border-${({isRtl}) => isRtl ? 'right' : 'left'}: 1px solid ${veryDarkTeal};
  }

  th:last-child,
  tr:not(.show-one-row, .subtotal-row, .final-total-row) td:last-child {
    border-${({isRtl}) => isRtl ? 'left' : 'right'}: 1px solid ${veryDarkTeal};
  }

  tr:not(.subtotal-row, .final-total-row):nth-child(even) td {
    background: ${veryLightGreen};
  }

  tr:not(.subtotal-row, .final-total-row):nth-child(odd) td {
    background: ${lightGreen};
  }

  tr.subtotal-row td {
    padding-bottom: 0;
  }

  tr.subtotal-row ~ tr.subtotal-row td {
    padding-top: 0;
  }

  tr.final-total-row td {
    padding-top: 0;
    font-weight: bold;
  }
`;

export const AdTableRow = ({row, i, contract, contractDetails, admin, imageLineItem, setImageLineItem, className}) => {
    const {openModal} = useContext(AppFunctionsContext);

    const {
        externalId,
        agent,
        date,
        signed,
        paid,
        allowAlternativePaymentPlan,
        comment,
        discount,
        totalBeforeMaam: cTotalBeforeMaam,
        maam: cMaam,
        total: cTotalWithMaam,
        guid: contractGuid,
    } = contract ?? {};

    const needsToPay = cTotalWithMaam > 0 && !(paid || signed);

    const openUploadModal = useCallback((contractLineItem) => {
        openModal({
            header: 'Advertisement Image',
            content: <UploadModal contractGuid={contractGuid} contractLineItem={contractLineItem}/>,
        });
    }, [contractGuid, contractDetails]);

    const togglShowingAd = useCallback(contractLineItem => {
        if (imageLineItem === contractLineItem) {
            setImageLineItem(undefined);
        } else {
            setImageLineItem(contractLineItem);
        }
    }, [imageLineItem]);

    const {
        id,
        type,
        quantity,
        placement,
        category,
        text,
        price,
        adImageId,
        adFilename,
        totalBeforeMaam,
        totalBeforeDiscount,
        total,
    } = row;
    //Size Info only has a value for actual ads, otherwise we treat it as a text listing
    const sizeInfo = adTypeSizesLookup[type];

    const queryClient = useQueryClient();

    const handleDownloadImage = useCallback(async () => {
        const data = await queryClient.fetchQuery({
            queryFn: () => getUpload(contractGuid, adImageId),
            queryKey: ["getUpload", adImageId],
            staleTime: Number.POSITIVE_INFINITY,
        });
        downloadBlob(data, adFilename);
    }, [contractGuid, adImageId, row]);

    return (
        <tr
            key={`row_${id}`}
            className={`${className} ${i === contractDetails.length - 1 ? 'last-row' : ''}`}
        >
            <td>
                {sizeInfo?.n ?? type}
            </td>
            <td>{quantity}</td>
            <td>{placement}</td>
            <td>{category}</td>
            <td colSpan={2}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {admin ?
                        adImageId ?
                            <Button active={row === imageLineItem} onClick={() => togglShowingAd(row)}>
                                {row === imageLineItem ? "Hide Ad" : "View Ad"}
                            </Button> : <></>
                        : sizeInfo && !needsToPay ?
                            <Button onClick={() => openUploadModal(row)}>
                                {adImageId ? "View Ad" : "Upload Ad"}
                            </Button> : <></>
                    }
                    {adImageId ?
                        <IconButtonDiv onClick={handleDownloadImage} style={{marginLeft: '10px'}}>
                            <FontAwesomeIcon icon={fas.faDownload}/>
                        </IconButtonDiv> : <></>
                    }
                </div>
                {text ? <div>{text}</div> : <></>}
            </td>
            <td>
                {ilsPriceDiv(total)}
                {/*{(totalBeforeMaam !== totalBeforeDiscount ?*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            borderTop: `1px dotted ${darkPurple}`,*/}
                {/*            paddingTop: '3px',*/}
                {/*            marginTop: '6px',*/}
                {/*            fontStyle: 'italic',*/}
                {/*            fontSize: '13px',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <div>*/}
                {/*            After&nbsp;Discount*/}
                {/*        </div>*/}
                {/*        <div>{ilsPriceDiv(totalBeforeMaam)}</div>*/}
                {/*    </div> : "")}*/}
            </td>
        </tr>
    );
};

export const AdDetailsTable = ({contract, contractDetails, admin, imageLineItem, setImageLineItem}) => {
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    const {
        externalId,
        agent,
        date,
        signed,
        paid,
        comment,
        discount,
        totalBeforeMaam: cTotalBeforeMaam,
        maam: cMaam,
        total: cTotalWithMaam,
        guid: contractGuid,
    } = contract ?? {};

    const itemsTotal = _.sumBy(contractDetails, 'totalBeforeDiscount');
    const discountAmount = cTotalBeforeMaam - itemsTotal;
    const showExtraSubtotalLine = _.size(contractDetails) > 1 && (discountAmount);

    return (
        <StyledAdDetailsTable isRtl={isRtl} style={{margin: '10px'}}>
            <thead>
            <tr>
                <th>{t('contract.details_ad_type')}</th>
                <th>{t('contract.details_quantity')}</th>
                <th>{t('contract.details_placement')}</th>
                <th>{t('contract.details_ad_category')}</th>
                <th colSpan={2}>{t('contract.details_content')}</th>
                <th style={{width: '80px'}}>{t('contract.details_price')}</th>
            </tr>
            </thead>
            <tbody>
            {_.map(contractDetails, (row, i) => (
                <AdTableRow
                    key={i}
                    i={i}
                    row={row}
                    contract={contract}
                    contractDetails={contractDetails}
                    admin={admin}
                    imageLineItem={imageLineItem}
                    setImageLineItem={setImageLineItem}
                />
            ))}
            <tr className={'subtotal-row'}>
                <td colSpan={5} rowSpan={5} style={{textAlign: 'left', padding: 0}}>
                    {comment?.trim() ?
                        <div style={{width: 'fit-content'}}>
                            <FieldWrapper style={{minWidth: '30vw'}}>
                                <FieldTitle>
                                    Comment
                                </FieldTitle>
                                <FieldContent>
                                    {comment?.trim()}
                                </FieldContent>
                            </FieldWrapper>
                        </div>
                        : <></>}
                </td>
                <td style={{textAlign: 'end', width: '180px'}}>
                    Subtotal
                </td>
                <td>
                    {showExtraSubtotalLine ? ilsPriceDiv(itemsTotal) : ilsPriceDiv(cTotalBeforeMaam)}
                </td>
            </tr>
            {discountAmount ?
                <tr className={'subtotal-row'}>
                    <td style={{textAlign: 'end'}}>
                        Discount
                    </td>
                    <td>
                        {ilsPriceDiv(discountAmount)}
                    </td>
                </tr> : <></>
            }
            {showExtraSubtotalLine ?
                <tr className={'subtotal-row'}>
                    <td style={{textAlign: 'end'}}>
                        Subtotal After Discount
                    </td>
                    <td>
                        {ilsPriceDiv(cTotalBeforeMaam)}
                    </td>
                </tr> : <></>
            }
            <tr className={'subtotal-row'}>
                <td style={{textAlign: 'end'}}>
                    {maamPercentage * 100}% MAAM
                </td>
                <td>
                    {ilsPriceDiv(cMaam)}
                </td>
            </tr>
            <tr className={'final-total-row'}>
                <td style={{textAlign: 'end'}}>
                    Total
                </td>
                <td>
                    {ilsPriceDiv(cTotalWithMaam)}
                </td>
            </tr>
            </tbody>
        </StyledAdDetailsTable>
    );
};