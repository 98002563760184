import {brightRed} from "../Components/BaseComponents";
import _ from "lodash";
import {adTypeSizesLookup} from "./data";

export const isProduction = true;
export const terminalNumber = isProduction ? 127726 : 1000;

//See: https://stackoverflow.com/a/14428340/5515589
export const formatNumber = number => Number(number || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");

export const ilsPriceDiv = amount => (
    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', direction: 'ltr'}}>
        <div>₪</div>
        <div style={{color: amount < 0 ? brightRed : undefined}}>{formatNumber(amount)}</div>
    </div>
);

//See: https://stackoverflow.com/a/63965930/5515589
export const downloadBlob = (blob, fileName) => {
    const link = document.createElement("a");
    link.href = blob;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

export const l = v => {
    console.log(v)
    return v;
}


export const requiredDpi = 300;

export const convertMmToPx = (dpi, mm) => dpi * mm / 25.4; //DPI * mm / 1 inch = px
export const getMmFromPxDpi = (px, dpi = 300) => px / dpi * 25.4; //px / mm * 1 inch = DPI
export const getDpiFromPxMm = (px, mm) => px / mm * 25.4; //px / mm * 1 inch = DPI

export const arrayify = a => _.isNil(a) || _.isArray(a) ? a : [a];

//https://pixelcalculator.com/en
export const getValidResolutions = size => {
    const sizes = arrayify(adTypeSizesLookup[size]);
    if (!sizes) return [];
    return _.map(sizes, s => [convertMmToPx(requiredDpi, s.w), convertMmToPx(requiredDpi, s.h), s]);
};

export const fitsResolutionRequirement = ([uploadWidth, uploadHeight], [requirementWidth, requirementHeight]) =>
    _.round(uploadWidth) >= _.round(requirementWidth) && _.round(uploadHeight) >= _.round(requirementHeight) &&
    uploadHeight > 0 && //Prevent divide by 0
    _.round(uploadWidth / uploadHeight, 2) === _.round(requirementWidth / requirementHeight, 2); 