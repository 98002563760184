import React, {useContext} from "react";
import {AppFunctionsContext} from "../App";
import {darkPurple, Heading} from "../Components/BaseComponents";
import {Button} from "../Components/Button";
import {format} from "date-fns";
import {InputField} from "../Components/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import {Formik} from "formik";
import styled from "styled-components";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useMutation, useQueryClient} from "react-query";
import {signContract} from "../functions/api";
import {toastMessages} from "../hooks/useToastMessages";

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #ffffff80;
  padding: 15px;
  overflow: auto;
  borderBottom: 1px solid ${darkPurple};
`;

const FieldWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const signatureSchema = yup.object().shape({
    name: yup.string().required('Required'),
    title: yup.string().required('Required'),
});


export const SignContractModal = ({contract}) => {
    const {closeModal, addToOverlaySet, removeFromOverlaySet} = useContext(AppFunctionsContext);
    const signContractQuery = useMutation({mutationFn: signContract, mutationKey: "signContract"});
    const queryClient = useQueryClient();

    const handleSubmit = async values => {
        const overlayId = 'submit-contract';
        console.log(values);

        try {
            addToOverlaySet(overlayId);
            const {name, title} = values || {};
            const ret = await signContractQuery.mutateAsync({
                contractGuid: contract.guid,
                name,
                title,
                date: new Date()
            });

            const {
                messageArray,
            } = ret || {};
            await queryClient.invalidateQueries(["getContract", contract.guid]);
            toastMessages(messageArray);
            closeModal();
        } catch (e) {
            toast.error(e.message);
        } finally {
            removeFromOverlaySet(overlayId);
        }
    }
    return (
        <div style={{width: "70vw", display: "flex", flexDirection: "column"}}>

            <Formik
                initialValues={{name: '', title: ''}}
                onSubmit={handleSubmit}
                validationSchema={signatureSchema}
            >{({isSubmitting, handleSubmit}) => (
                <StyledForm>
                    <StyledInnerWrapper>
                        <Heading><FontAwesomeIcon icon={fas.faFileSignature}/></Heading>
                        <Heading> Personal Responsibility / ערבות אישית</Heading>
                        <p style={{fontStyle: 'italic'}}>Date: {format(new Date(), 'MMMM dd yyyy')}</p>
                        <p>
                            I, the undersigned, undertake to pay in full costs of the ad I have reserved for my business
                            within 30 days of the signing of this contract,
                            by credit card, check or bank transfer. I am aware that my ad will not be printed if I do
                            not pay
                            according to our agreement.
                        </p>
                        <p style={{direction: "rtl"}}>
                            אני הח''מ ערב אישית עבור התשלום בגין הפרסומים והזמנות של החברה לעיל תוך 30 יום מחתימת שטר
                            זה. אני מודע לזאת שאי תשלום בזמו יגרום אי הדפסת הפרסומים שהזמנתי.
                        </p>
                        <FieldWrapper>
                            <InputField name={'name'} type={'text'} label={'Name'}/>
                        </FieldWrapper>
                        <FieldWrapper>
                            <InputField name={'title'} type={'text'} label={'Title'}/>
                        </FieldWrapper>
                        <FieldWrapper style={{textAlign: 'center'}}>
                            <Button type={'submit'} onClick={handleSubmit} disabled={isSubmitting}
                                    style={{width: '200px'}}>
                                Submit Signature
                            </Button>
                            <Button onClick={closeModal} style={{margin: '10px'}}>Close</Button>
                        </FieldWrapper>
                    </StyledInnerWrapper>
                </StyledForm>
            )}
            </Formik>
        </div>
    );
};