import React from "react";

export const LandingPage = () => {
    return (
        <div style={{padding: '10px', textAlign: 'center'}}>
            Welcome to the Newcomers Guide advertiser portal!
            <br/>
            Contact us to reserve your ad:
            <br/>
            <a href="mailto:ads@newcomersguide.co.il">ads@newcomersguide.co.il</a>
        </div>
    );
};