import {darkGreen} from "../Components/BaseComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import React from "react";

export const LoadingSpinner = () =>
    (<div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '36px',
            color: darkGreen,
        }}>
            <FontAwesomeIcon icon={fas.faCog} spin/>
        </div>
    )