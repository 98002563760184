import React, {useCallback, useContext, useEffect, useState} from "react";
import _ from "lodash";
import styled from "styled-components";
import {useHistory, useParams} from "react-router";
import {useMutation, useQuery} from "react-query";
import {useTranslation} from "react-i18next";
import {darkGreen, lightGreen, PageTitle, PageWrapper, SecondaryTitle} from "../Components/BaseComponents";
import {Button} from "../Components/Button";
import {AppFunctionsContext} from "../App";
import dayjs from "dayjs";
import {getContract, getPaymentStatus} from "../functions/api";
import {useIntervalWhen} from "rooks";
import {TermsAndConditionsModal} from "./TermsAndConditionsModal";
import {PaymentModal} from "./PaymentModal";
import {AdDetailsTable} from "./AdDetailsTable";
import {SignContractModal} from "./SignContractModal";

const ContractDetailsTable = styled.table`
    border-collapse: collapse;
    margin: 10px 20px;
    width: fit-content;

    td:first-child {
        font-weight: bold;
        color: ${darkGreen};
        width: 140px;
    }

    tr:not(:last-child) td {
        border-bottom: 1px solid ${lightGreen};
    }
`;

const CancellationPolicyDiv = styled.div`
    font-style: italic;
`;

export const maamPercentage = 0.17;

const loadingContractOverlayKey = 'loading-contract';

export const Contract = () => {
    //TODO If id does not exist show does not exist with a contact form...
    //TODO While loading show a loading symbol...
    const {addToOverlaySet, removeFromOverlaySet, openModal, closeModal} = useContext(AppFunctionsContext);
    const {id: contractGuid} = useParams();
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";
    const [payModalOpen, setPayModalOpen] = useState(false);

    const contractInfoQ = useQuery({queryFn: () => getContract(contractGuid), queryKey: ["getContract", contractGuid]});

    useEffect(() => {
        if (contractInfoQ.isLoading) {
            addToOverlaySet(loadingContractOverlayKey);
        } else {
            removeFromOverlaySet(loadingContractOverlayKey);
        }
    }, [contractInfoQ.isLoading]);

    const {
        data,
        success,
        messageArray,
    } = contractInfoQ?.data ?? {};

    const {
        client,
        contractDetails,
        contract,
    } = data ?? {}; //data could be null (instead of undefined) so we need to destructure it separately and test for null - see: https://stackoverflow.com/questions/56972446/cannot-destructure-property-of-null

    const {
        name,
        ncgClientNumber,
        contactName,
        contactEmail,
        contactCell,
        contactPhone,
        contactFax,
        billingName,
        billingAddress,
        billingPhone,
        billingId,
    } = client ?? {};

    const {
        externalId,
        agent,
        date,
        signed,
        paid,
        comment,
        discount,
        totalBeforeMaam: cTotalBeforeMaam,
        maam: cMaam,
        total: cTotalWithMaam,
        allowAlternativePaymentPlan,
    } = contract ?? {};

    //Keep checking as long as it hasnt yet been marked as paid
    const paymentStatusQ = useMutation({
        mutationFn: () => getPaymentStatus(contractGuid),
        mutationKey: ["getPaymentStatus", contractGuid],
        onSuccess: resp => {
            const {data: paid} = resp || {};
            if (paid && payModalOpen) {
                setPayModalOpen(false);
                closeModal();
            }
        },
    });

    useIntervalWhen(() => {
        paymentStatusQ.mutateAsync();
    }, 1000, payModalOpen, false);

    const openPaymentModal = useCallback(() => {
        setPayModalOpen(true);
        openModal({
            header: 'Payment',
            content: <PaymentModal contractGuid={contractGuid} setPayModalOpen={setPayModalOpen}/>,
        });
    }, []);

    const openTermsAndConditionsModal = useCallback(() => {
        openModal({
            header: 'Terms and Conditions',
            content: <TermsAndConditionsModal/>,
        });
    }, []);

    const openSignContractModal = useCallback(() => {
        openModal({
            header: 'Sign Contract',
            content: <SignContractModal contract={contract}/>,
        });
    }, [contract]);

    const [confirmTerms, setConfirmTerms] = useState();

    if (contractInfoQ.isLoading) {
        return (
            <></>
        );
    }

    if (success === false || !client || !contract) {
        return <div style={{
            color: 'red',
            fontWeight: 'bold',
            fontSize: '20px',
            margin: '20px',
            textAlign: 'center',
        }}>
            {_.map(messageArray, m => <>{m?.message}</>)}
        </div>;
    }

    return (
        <PageWrapper>
            <PageTitle style={{
                paddingBottom: '5px',
                marginBottom: '10px',
                borderBottom: `1px solid ${lightGreen}`,
            }}>{t('contract.page_title')}</PageTitle>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: 'fit-content',
                margin: '0 auto',
            }}>
                <SecondaryTitle>{t('contract.contract_info')}</SecondaryTitle>
                <ContractDetailsTable>
                    <tbody>
                    <tr>
                        <td>{t('contract.date')}</td>
                        <td>{dayjs(date).format("DD MMM YYYY")}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.agent')}</td>
                        <td>{agent}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.contract_number')}</td>
                        <td>{ncgClientNumber}</td>
                    </tr>
                    </tbody>
                </ContractDetailsTable>
                <SecondaryTitle>{t('contract.client_info')}</SecondaryTitle>
                <ContractDetailsTable>
                    <tbody>
                    <tr>
                        <td>{t('contract.name')}</td>
                        <td>{name}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.contact_person')}</td>
                        <td>{contactName}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.contact_phone')}</td>
                        <td>{contactPhone}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.contact_cell')}</td>
                        <td>{contactCell}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.contact_email')}</td>
                        <td>{contactEmail}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.contact_fax')}</td>
                        <td>{contactFax}</td>
                    </tr>
                    </tbody>
                </ContractDetailsTable>
                <SecondaryTitle>{t('contract.billing_info')}</SecondaryTitle>
                <ContractDetailsTable>
                    <tbody>
                    <tr>
                        <td>{t('contract.billing_name')}</td>
                        <td>{billingName}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.billing_address')}</td>
                        <td>{billingAddress}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.billing_phone')}</td>
                        <td>{billingPhone}</td>
                    </tr>
                    <tr>
                        <td>{t('contract.billing_id')}</td>
                        <td>{billingId}</td>
                    </tr>
                    </tbody>
                </ContractDetailsTable>
                <SecondaryTitle>{t('contract.advertisement_details')}</SecondaryTitle>
                <AdDetailsTable contract={contract} contractDetails={contractDetails}/>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                borderTop: `1px solid ${lightGreen}`,
                paddingTop: '10px',
            }}>
                {<div style={{margin: '0 0 10px 10px'}}>
                    <b>IMPORTANT:</b> If you have any questions or would like to make changes, please call our office so
                    we can update the contract.
                </div>}
                <CancellationPolicyDiv style={{margin: '0 0 10px 10px'}}>
                    Cancellation Policy: In case of cancellation within 7 days of placing an order; 50% will be
                    refunded.<br/>
                    After 7 days, there will be no refund for any cancellation.<br/>
                    Newcomers Guide reserves the right to refuse the publication of any ad for any reason.
                </CancellationPolicyDiv>
                <div style={{margin: '10px'}}>
                    <div style={{marginBottom: '10px'}}>
                        {cTotalWithMaam === 0 ?
                            null :
                            paid ?
                                <div style={{color: darkGreen, fontWeight: 'bold'}}>
                                    This contract has been paid
                                </div> :
                                signed ? <div style={{
                                        color: darkGreen,
                                        fontWeight: 'bold',
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 6
                                    }}>
                                        This contract has been signed
                                    </div> :
                                    <>
                                        <div style={{margin: !isRtl ? '0 10px 10px 0' : '0 0 10px 10px'}}>
                                            <input
                                                type={"checkbox"}
                                                checked={confirmTerms}
                                                onChange={() => setConfirmTerms(v => !v)}
                                            /> I confirm that <b>I have reviewed the details above and they are
                                            correct</b>
                                            <br/>
                                            and <b>I accept the <span
                                            style={{color: "blue", textDecoration: "underline", cursor: "pointer"}}
                                            onClick={openTermsAndConditionsModal}>terms and conditions
                                            </span> for the
                                            ad.</b>
                                        </div>
                                        {allowAlternativePaymentPlan ? <Button
                                            onClick={openSignContractModal}
                                            style={{width: '30vw', minWidth: '200px'}}
                                            disabled={!confirmTerms}
                                        >
                                            Confirm & Sign
                                        </Button> : <Button
                                            onClick={openPaymentModal}
                                            style={{width: '30vw', minWidth: '200px'}}
                                            disabled={!confirmTerms}
                                        >
                                            Confirm & Pay
                                        </Button>}
                                    </>
                        }
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};