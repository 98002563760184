import {Auth, Hub, Logger} from "aws-amplify";
import {useMemo, useState} from "react";
import {useQuery} from "react-query";
import _ from "lodash";
import {queryClient} from "../App";

export const useAuth = () => {
    const [loginStatusChecked, setLoginStatusChecked] = useState(false);
    const {data: user} = useQuery("currentUser", async () => {
        try {
            return await Auth.currentAuthenticatedUser();
        } catch (e) {
            // console.error(e)
        }
    }, {
        onSettled: () => {
            setLoginStatusChecked(true);
        },
    });

    const getUserRoles = useMemo(() => {
        const roles = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];

        const isAdmin = roles.includes("Admin");

        return {
            roles,
            isAdmin,
        };
    }, [user]);

    // console.log(user);

    return useMemo(() => {
        const email = user?.attributes?.email;


        return {
            loggedIn: !_.isNil(user),
            user,
            ...getUserRoles,
            loginStatusChecked,
            email,
        };
    }, [user, loginStatusChecked]);
};

const logger = new Logger('My-Logger');

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            queryClient.setQueryData("currentUser", data.payload.data);
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signOut':
            logger.info('user signed out');
            queryClient.setQueryData("currentUser", undefined);
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
    }
};

Hub.listen('auth', listener);