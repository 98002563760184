import React, {useContext} from "react";
import {AppFunctionsContext} from "../App";
import {darkPurple} from "../Components/BaseComponents";
import {Button} from "../Components/Button";

export const TermsAndConditionsModal = () => {
    const {closeModal} = useContext(AppFunctionsContext);

    return (
        <div style={{height: "75vh", width: "70vw", display: "flex", flexDirection: "column"}}>
            <div style={{
                padding: '15px',
                overflow: 'auto',
                textAlign: 'justify',
                borderBottom: `1px solid ${darkPurple}`,
            }}>
                <p style={{fontStyle: 'italic'}}>Last updated: May 15, 2024</p>
                <h1>
                    General
                </h1>
                <p>
                    The book will be"H be printed by September 2024.
                    <br/>
                    Your ad will be printed in the category that you sign on the contract. As well, the ad that will be
                    printed will be the one that is uploaded on the site or or that you approve through email.
                    <br/>
                    Your card will be charged as soon as you approve the contract, unless otherwise specified by an
                    agent in our office.
                    <br/>
                    We guarantee that if your ad is approved, it will be in the book or you will be compensated.
                    If there are any issues with the Ad, please contact us.
                    <br/>
                    Newcomers Guide reserves the right to refuse the publication of any ad for any reason.
                </p>
                <h1>
                    Cancellation Policy
                </h1>
                <p>
                    In case of cancellation within 7 days of placing an order; 50% will be
                    refunded.
                    <br/>
                    After 7 days, there will be no refund for any cancellation.<br/>
                </p>
                <h1>Privacy Policy</h1>
                We do not pass on your information to any 3rd party.

                <h1>Contact Us</h1>
                <p>If you have any questions, You can contact us:</p>
                <ul>
                    <li>
                        <p>By email: ads@newcomersguide.co.il</p>
                    </li>
                    <li>
                        <p>By visiting this page on our website: <a href="https://newcomersguide.co.il/"
                                                                    rel="external nofollow noopener"
                                                                    target="_blank">https://newcomersguide.co.il/</a>
                        </p>
                    </li>
                    <li>
                        <p>By phone number: 1-599-500-605</p>
                    </li>
                    <li>
                        <p>By mail: POB 41064 Jerusalem, 91410</p>
                    </li>
                </ul>
            </div>
            <Button onClick={closeModal} style={{margin: '10px'}}>Close</Button>
        </div>
    );
};