import React, {useContext} from "react";
import {AppFunctionsContext} from "../App";
import {darkPurple} from "../Components/BaseComponents";
import {terminalNumber} from "../functions/shared";
import {Button} from "../Components/Button";
import {useQuery} from "react-query";
import {getPaymentPageCode} from "../functions/api";
import {LoadingSpinner} from "./LoadingSpinner";
import {useToastMessages} from "../hooks/useToastMessages";

export const PaymentModal = ({contractGuid, setPayModalOpen}) => {
    const {closeModal} = useContext(AppFunctionsContext);

    const lowProfileCodeQ = useQuery({
        queryFn: () => getPaymentPageCode(contractGuid),
        queryKey: ["getPaymentPageCode", contractGuid],
        refetchInterval: 60 * 60 * 1000,
        staleTime: 60 * 60 * 1000, //1 hour
    });

    const {
        data: lowProfileCode,
        messageArray,
    } = lowProfileCodeQ.data || {};

    useToastMessages(messageArray);

    return (
        <div style={{height: "75vh", width: "70vw", display: "flex", flexDirection: "column"}}>
            {lowProfileCodeQ.isLoading ? <LoadingSpinner/> : <iframe
                style={{height: '100%', width: '100%', border: 'none', borderBottom: `1px solid ${darkPurple}`}}
                src={`https://secure.cardcom.solutions/External/lowProfileClearing/${terminalNumber}.aspx?LowProfileCode=${lowProfileCode}`}
            />}
            <Button onClick={() => {
                closeModal();
                setPayModalOpen(false);
            }} style={{margin: '10px'}}>Close</Button>
        </div>
    );
};