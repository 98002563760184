import {adTypeSizesLookup} from "../functions/data";
import {useQuery} from "react-query";
import {getUpload} from "../functions/api";
import _ from "lodash";
import {LoadingSpinner} from "../Pages/LoadingSpinner";
import React from "react";

export const ImageDisplay = ({contractGuid, contractLineItem, showImageMeta}) => {
    const {
        id,
        adImageId,
        adFilename,
        type,
    } = contractLineItem;

    const sizeInfo = adTypeSizesLookup[type];

    const {data: image, isLoading} = useQuery({
        queryFn: () => getUpload(contractGuid, adImageId),
        queryKey: ["getUpload", adImageId],
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: Number.POSITIVE_INFINITY,
        enabled: !!adImageId, //Convert to boolean
    });
    

    const extension = _.toLower(_.last(_.split(adFilename, '.')));
    const isPDF = extension === "pdf";

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            overflow: 'auto',
            height: '100%',
        }}>
            {isLoading ?
                <LoadingSpinner/> :
                isPDF ?
                    <embed
                        key={image} //This will cause the embed to fully rerender when the source changes, preventing a sizing issue when switching from pdf to image because the pdf document is otherwise still nested in the embed tag.  See: https://github.com/facebook/react/issues/12665#issuecomment-383332682
                        src={image}
                        style={{height: '100%', width: '100%'}}
                    /> :
                    <img
                        key={image}
                        src={image}
                        alt={sizeInfo?.n}
                        style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                            height: 'max-content',
                            objectFit: 'contain',
                        }}
                    />
            }
        </div>
    );
};